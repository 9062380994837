import React, { useContext, useEffect, useState } from "react";
import logo from "../../assets/image/logo2.png";

import Axios from "../../axios/axiosInstance";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/AppContext";
import axios from "axios";

function Login() {
  const [input, setInput] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState();
  const navigate = useNavigate();
  const { setSpinner } = useContext(AppContext);
  const [validationError, setValidationError] = useState({});

  useEffect(() => {
    if (!localStorage.getItem("AUTH_USER_TOKEN_KEY")) {
      navigate("/");
    }
  }, []);

  const validateForm = () => {
    const errors = {};

    if (!input.email.trim()) {
      errors.email = "Email is required";
    }

    if (!input.password.trim()) {
      errors.password = "Password is required";
    }

    setValidationError(errors);

    return Object.keys(errors).length === 0;
  };

  const onSubmit = () => {
    if (validateForm()) {
      setSpinner(true);
      let obj = {
        email: input.email,
        password: input.password,
        userType: "admin",
      };
      axios
        .post(`${process.env.REACT_APP_BASE_URL}api/auth/login`, obj)
        .then(function (response) {
          setSpinner(false);
          console.log("response", response);
          localStorage.setItem(
            "AUTH_USER_TOKEN_KEY",
            JSON.stringify(response.data.data.access_token)
          );
          navigate("/prompts");
        })
        .catch(function (error) {
          if (error.response && error.response.status === 401) {
            setError("Incorrect username or password.");
          } else {
            setError(error?.response?.data?.message);
          }

          setSpinner(false);
        });
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setInput((prev) => ({ ...prev, [name]: value }));
    setValidationError((prevErrors) => ({ ...prevErrors, [name]: "" }));
    setError("")
  };

  console.log("input", input);
  return (
    <div className="login-area login-bg">
      {/* // <div id="preloader">
        //   <div className="loader"></div>
        // </div> */}
      <div className="container">
        <div className="login-box ptb--100 d-block text-center ">
          <div className="logo_login mb-3">
            <a href="index.html">
              <img src={logo} alt="logo" />
              humanStreams
            </a>
          </div>
          <form>
            <div className="login-form-head">
              <h4 className="mb-0">Log In</h4>
            </div>
            <div className="login-form-body">
              <div className="form-gp">
                <label htmlFor="exampleInputEmail1">Username</label>
                <br />
                <input
                  type="email"
                  id="exampleInputEmail1"
                  name="email"
                  value={input.email}
                  onChange={handleChange}
                  // onBlur={(e) => this.validateFields("username")}
                />
                <i className="ti-email"></i>
                <p style={{ color: "red" }}>{validationError.email}</p>
                {/* <p style={{ color: "red" }}>
                  {this.state.errors?.username
                    ? this.state.errors?.username
                    : ""}
                </p> */}
              </div>
              <div className="form-gp">
                <label htmlFor="exampleInputPassword1">Password</label>
                <br />
                <input
                  type="password"
                  id="exampleInputPassword1"
                  name="password"
                  value={input.password}
                  onChange={handleChange}
                  // onBlur={(e) => this.validateFields("password")}
                />
                <i className="ti-lock"></i>
                <p style={{ color: "red" }}>{error ? error : ""}</p>
                <p style={{ color: "red" }}>{validationError.password}</p>
              </div>

              <div className="submit-btn-area">
                <a
                  id="form_submit"
                  type="button"
                  // onClick={() => navigate("/prompts")}
                  onClick={() => onSubmit()}
                  style={{ cursor: "pointer" }}
                >
                  Submit
                </a>
              </div>
              {/* <p className="text-danger">{this.state.errMessage}</p> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
