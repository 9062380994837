import Axios from "../../axios/axiosInstance";
import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AppContext from "../../context/AppContext";
import DelStoryFWithCat from "./DelStoryFWithCat";

function StoryFlowWithCats() {
  const [promptArray, setPromptArray] = useState([]);
  const [filterValue, setFilterValue] = useState();
  const [selectedValue, setSelectedValue] = useState();
  const { spinner, setSpinner } = useContext(AppContext);
  const token = JSON.parse(localStorage.getItem("AUTH_USER_TOKEN_KEY"));
  const [delModal, setDelModal] = useState({
    show: false,
    id: "",
  });

  const getPromptsList = (filterValue) => {
    // method: "onlyListPrompt
    setSpinner(true);
    setPromptArray([]);
    Axios
      .get(
        `${process.env.REACT_APP_BASE_URL}api/Prompt/storyWithCat?StoryFlowCategory=${filterValue}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function (response) {
        // console.log(response);
        setSpinner(false);

        // that.setState({ preloader: false, promptArray: response.data.data });
        console.log("response", response);
        setPromptArray(response.data.data);
      })
      .catch(function (error) {
        setSpinner(false);
        console.log(error);
        // that.setState({ preloader: false });
      });
  };

  const getCategoryList = () => {
    setSpinner(true);
    Axios
      .get(`${process.env.REACT_APP_BASE_URL}api/StoryFlowCategory`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        setSpinner(false);
        console.log("response", response.data.data);
        setFilterValue({
          categoryArray: response.data.data,
          filterValue: response.data.data[0].id,
          prompt_cat_name: response.data.data[0].name,
        });
        setSelectedValue(response.data.data[0].id);
      })
      .catch(function (error) {
        setSpinner(false);
        console.log(error);
      });
  };

  // drag and drop function
  const handleDrag = (result) => {
    console.log("promptyArrAy", promptArray);
    let tempUser = [...promptArray];
    console.log("tempUser1111", tempUser);
    let [selectedRow] = tempUser.splice(result.source.index, 1);
    tempUser?.splice(result?.destination?.index, 0, selectedRow);
    setPromptArray(tempUser);
    console.log("tempUser", tempUser);
    let newArr = tempUser.map((items, index) => {
      return {
        id: items.id,
        order_idx: index,
      };
    });
    console.log("newArray", newArr);
    dragAndDropApiCall(newArr);
  };

  const dragAndDropApiCall = (oderItems) => {
    setSpinner(true);
    const obj = {
      data: oderItems,
    };
    Axios
      .put(`${process.env.REACT_APP_BASE_URL}api/Prompt/dragStoryWithCat`, obj, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        setSpinner(false);
      })
      .catch(function (error) {
        setSpinner(false);
      });
  };

  useEffect(() => {
    if (selectedValue) getPromptsList(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    getCategoryList();
  }, []);

  return (
    <div className="body-bg">
      <div className="horizontal-main-wrapper">
        <div className="main-content-inner">
          <div className="container">
            <div className="row">
              <div className="col-12 mt-5">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between mb-3">
                      <div className="col-md-2 col-6">
                        <select
                          className="form-control bgSelectCol "
                          style={{padding: '0.375rem 0.7rem',}}
                          value={selectedValue}
                          onChange={(e) => setSelectedValue(e.target.value)}
                        >
                          {filterValue?.categoryArray.map((category, index) => {
                            return (
                              <option className="mb-2" value={category.id}>
                                {category.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="single-table">
                      <div className="table-responsive">
                        <DragDropContext onDragEnd={(results) => handleDrag(results)}>
                          <table className="table table-hover progress-table text-center">
                            <thead className="text-uppercase">
                              <tr>
                                <th scope="col">No</th>
                                <th scope="col">PROMPT NAME</th>
                                <th scope="col">CATEGORY NAME</th>
                                <th scope="col">POSITION</th>
                                <th scope="col">DESCRIPTION</th>
                                <th scope="col">ACTION</th>
                              </tr>
                            </thead>
                            <Droppable droppableId={promptArray[0]}>
                              {(provided) => (
                                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                  {promptArray?.length
                                    ? promptArray?.map((data, index) => {
                                        return (
                                          <Draggable
                                            draggableId={"" + data.id}
                                            index={index}
                                            key={data.id}
                                            type="TASK"
                                          >
                                            {(provider) => (
                                              <tr
                                                key={data.id}
                                                ref={provider.innerRef}
                                                {...provider.draggableProps}
                                                {...provider.dragHandleProps}
                                              >
                                                <th scope="row">{index + 1}</th>
                                                <td>{data?.Prompt?.name}</td>
                                                <td>{data?.Prompt?.ProgramCategories[0]?.name}</td>
                                                <td>{index + 1 }</td>
                                                <td>{data?.Prompt?.description}</td>
                                                <td>
                                                  <ul className="d-flex justify-content-center">
                                                    <li>
                                                      <a
                                                        href={
                                                          process.env.REACT_APP_MEDIA_URL +
                                                          data?.Prompt?.image
                                                        }
                                                        target="_blank"
                                                      >
                                                        <span className="status-p ">
                                                          <i className="fa fa-eye"></i>
                                                        </span>
                                                      </a>
                                                    </li>
                                                    <li>
                                                      <a
                                                        href={
                                                          process.env.REACT_APP_MEDIA_URL +
                                                          data?.Prompt?.audio
                                                        }
                                                        target="_blank"
                                                      >
                                                        <span className="status-p ">
                                                          <i className="fa fa-play"></i>
                                                        </span>
                                                      </a>
                                                    </li>

                                                    <li>
                                                      <a
                                                        onClick={() =>
                                                          setDelModal({
                                                            show: true,
                                                            id: data.Prompt.id,
                                                          })
                                                        }
                                                      >
                                                        <span
                                                          className="status-p "
                                                          data-toggle="modal"
                                                          data-target="#exampleModalCenter2"
                                                        >
                                                          <i className="fa fa-trash"></i>
                                                        </span>
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </td>
                                              </tr>
                                            )}
                                          </Draggable>
                                        );
                                      })
                                    : !spinner && (
                                        <tr>
                                          <td colspan="6">{"No Result found!"}</td>
                                        </tr>
                                      )}
                                </tbody>
                              )}
                            </Droppable>
                          </table>
                        </DragDropContext>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {delModal.show && (
          <DelStoryFWithCat
            delModal={delModal}
            setDelModal={setDelModal}
            getPromptsList={getPromptsList}
            selectedValue={selectedValue}
          />
        )}
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default StoryFlowWithCats;
