import Axios from '../../axios/axiosInstance';
import React, { useContext, useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import AppContext from '../../context/AppContext';
import AddEditTag from './AddEditTag';
import DeleteModal from './DeleteModal';

function Tags() {
  const [tagsArray, setTagsArray] = useState([])
  const [modal, setModal] = useState({
    show:false,
    type:""
  });
  const [tagName, setTagName] = useState()
  const [delModal,setDelModal] = useState({
    show:false
  });
  const { spinner,setSpinner } = useContext(AppContext);
  const token = JSON.parse(localStorage.getItem("AUTH_USER_TOKEN_KEY"));

  const getTagsList = () => {
    setSpinner(true);
    Axios
      .get(
        `${process.env.REACT_APP_BASE_URL}api/PromptTag?page=1&limit=10`,
        {
          headers:{
            "Authorization": "Bearer "+token
          }
        }
      )
      .then(function (response) {
        console.log("response", response);
        setTagsArray(response.data.data);
        setSpinner(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

   // drag and drop function
  const handleDrag = (result) => {
    let tempUser = [...tagsArray];
    let [selectedRow] = tempUser.splice(result.source.index, 1);
    tempUser?.splice(result?.destination?.index, 0, selectedRow);
    let newArr = tempUser.map((items, index) => {
      return {
       ...items,
        order_idx: index,
      }
    });
    setTagsArray(newArr);
    dragAndDropApiCall(newArr);
  };

  const dragAndDropApiCall = (oderItems) => {
    setSpinner(true);
    const obj = {
      data: oderItems,
    };
    Axios
      .put(`${process.env.REACT_APP_BASE_URL}api/PromptTag`, obj, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        setSpinner(false);
      })
      .catch(function (error) {
        setSpinner(false);
      });
  };

  useEffect(() => {
    getTagsList();
  },[])
  return (
    <div className="body-bg">
    {/* {state.preloader && (
      <div id="preloader">
        <div className="loader"></div>
      </div>
    )} */}
    <div className="horizontal-main-wrapper">
      {/* <Header _this={this} history={props.history} /> */}
      <div
        className="main-content-inner"
        // onClick={() => setState({ showMenu: false })}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 mt-5">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="listHead">
                      Tags
                    </p>
                    <input
                      className="btn btn-info mb-3 ml-auto"
                      data-toggle="modal"
                      data-target=".bd-example-modal-sm"
                      type="submit"
                      value="Add New"
                      // onClick={() => setState({ addNew: true })}
                      onClick={() => setModal({ show: true, type:"add" })}

                    />
                  </div>
                  <div className="single-table">
                    <div className="table-responsive">
                    <DragDropContext
                          onDragEnd={(results) => handleDrag(results)}
                        >
                          <table className="table table-hover progress-table text-center">
                            <thead className="text-uppercase">
                              <tr>
                                <th scope="col">NO</th>
                                <th scope="col">Tag NAME</th>
                                <th scope="col">POSITION</th>
                                <th scope="col">ACTION</th>
                              </tr>
                            </thead>
                            <Droppable droppableId={tagsArray[0]}>
                              {(provided) => (
                                <tbody
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  {tagsArray?.length ? (
                                    tagsArray?.map((data, index) => {
                                      return (
                                        <Draggable
                                          draggableId={"" + data.id}
                                          index={index}
                                          key={data.id}
                                          type="TASK"
                                        >
                                          {(provider) => (
                                            <tr
                                              key={data.id}
                                              ref={provider.innerRef}
                                              {...provider.draggableProps}
                                              {...provider.dragHandleProps}
                                            >
                                              {console.log("data", data)}

                                              <th scope="row">
                                                {data ? index + 1 : ""}
                                              </th>
                                              <td>{data.name}</td>
                                              <td>
                                                {data ? data.order_idx + 1 : ""}
                                              </td>
                                              <td>
                                                <ul className="d-flex justify-content-center">
                                                  <li>
                                                    <a
                                                      onClick={() => {
                                                        setModal({
                                                          show: true,
                                                          type: "edit",
                                                          id: data.id,
                                                        });
                                                        setTagName(data.name);
                                                      }}
                                                    >
                                                      <span
                                                        className="status-p "
                                                        data-toggle="modal"
                                                        data-target="#exampleModalCenter2"
                                                      >
                                                        <i className="fa fa-pencil"></i>
                                                      </span>
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      onClick={() =>
                                                        setDelModal({
                                                          show: true,
                                                          id: data.id,
                                                        })
                                                      }
                                                    >
                                                      <span
                                                        className="status-p "
                                                        data-toggle="modal"
                                                        data-target="#exampleModalCenter2"
                                                      >
                                                        <i className="fa fa-trash"></i>
                                                      </span>
                                                    </a>
                                                  </li>
                                                </ul>
                                              </td>
                                            </tr>
                                          )}
                                        </Draggable>
                                      );
                                    })
                                  ) : (!spinner &&
                                    <tr>
                                      <td colspan="4">{"No Result found!"}</td>
                                    </tr>
                                  )}
                                </tbody>
                              )}
                            </Droppable>
                          </table>
                        </DragDropContext>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal.show && 
      <AddEditTag
       modal={modal}
       setModal={setModal}
       tagName={tagName}
       setTagName={setTagName}
       getTagsList={getTagsList}
       />  }
       {delModal.show && 
       <DeleteModal
       delModal={delModal}
       setDelModal={setDelModal}
       getTagsList={getTagsList}
       /> }                      
    </div>
    {/* <Footer /> */}
  </div>
  )
}

export default Tags
