import React from 'react'

function DelCatTag({show, setShow, deleteApi, name}) {
  return (
     <div
          className={`modal fade bd-example-modal-sm ${
            show.show ? "show" : ""
          }`}
          style={{
            display: `${show.show ? "block" : "none"}`,
          }}
          id="exampleModalCenter2"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-sm"
            role="document"
          >
            <div className="modal-content">
              <div className="login-area p-0">
                <div className="login-box d-block  text-center">
                  <form className="m-0">
                    <div className="login-form-head">
                      <img src="./assets/image/delete.svg" alt="" />
                    </div>
                    <div className="login-form-body">
                      <div className="">
                        <h4 className="pop_up_text">
                          Are you sure you want to delete this {name} ?
                        </h4>
                      </div>

                      <div className="submit-btn-area mt-4 d-flex">
                        <a
                          onClick={() =>{
                            // setState({
                            //   ShowDeleteModel: false,
                            //   deleteId: 0,
                            // })
                            setShow({
                              show:false,
                              id:""
                            })
                          }
                           
                          }
                          id="form_submit"
                          className="mr-2"
                          type="button"
                          style={{ cursor: "pointer" }}
                        >
                          Cancel
                        </a>
                        <a
                          onClick={() => {
                            deleteApi()
                          }}
                          id="form_submit"
                          type="button"
                          style={{ cursor: "pointer" }}
                        >
                          delete
                        </a>
                      </div>
                      <p style={{ color: "red" }}>
                        {/* {state.errors?.image_obj
                          ? state.errors?.image_obj
                          : ""} */}
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div> 
        
  )
}

export default DelCatTag
