import Axios from "../../axios/axiosInstance";
import React, { Fragment, useContext, useEffect, useState } from "react";
import ReactSelect from "react-select";
import AppContext from "../../context/AppContext";

function AddEditProg({ modal, setModal, getProgramList, errors, setErrors, page, selectedValue }) {
  const [options, setOptions] = useState({
    categoriesList: [],
    promptList: [],
    tagsList: [],
  });
  const [promptArray, setPromptArray] = useState([{}, {}, {}, {}, {}]);
  const { setSpinner } = useContext(AppContext);
  const [selectedcat, setSelectedCat] = useState();
  const [selectedTag, setSelectedTag] = useState([]);
  const token = JSON.parse(localStorage.getItem("AUTH_USER_TOKEN_KEY"));

  const validationHandle = () => {
    console.log("test");
    const errorText = "This field is required";
    if (selectedTag.length < 1) {
      setErrors((prev) => ({ ...prev, selectedTag: errorText }));
    }
    if (!selectedcat) {
      setErrors((prev) => ({ ...prev, selectedcat: errorText }));
    }
    if (
      !promptArray[0].label ||
      !promptArray[1].label ||
      !promptArray[2].label ||
      !promptArray[3].label ||
      !promptArray[4].label
    ) {
      setErrors((prev) => ({ ...prev, promptArray: errorText }));
    } else if (
      selectedcat &&
      selectedTag.length > 0 &&
      promptArray[0].label &&
      promptArray[1].label &&
      promptArray[2].label &&
      promptArray[3].label &&
      promptArray[4].label
    ) {
      console.log("trueeeeeeeeeeeee", true);
      return true;
    }
  };

  // recommended program list
  const getRecommendedCategoryList = () => {
    Axios.get(`${process.env.REACT_APP_BASE_URL}api/ProgramCategory?page=1&limit=10`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(function (response) {
        console.log("response2222", response);

        let optionArray = response.data.data.map((ele) => {
          return { label: ele.name, value: ele.id };
        });
        setOptions((prev) => ({
          ...prev,
          categoriesList: optionArray,
        }));
        // that.setState({
        //   recommendedCatArray: response.data.data.Items,
        //   recommendedCatList: optionArray,
        // });
        // that.getPromptsList();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // recommended list
  const getRecommendedTagList = () => {
    Axios.get(`${process.env.REACT_APP_BASE_URL}api/ProgramTag?page=1&limit=10`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(function (response) {
        let optionArray = response.data.data.map((ele) => {
          return { label: ele.name, value: ele.id };
        });
        setOptions((prev) => ({ ...prev, tagsList: optionArray }));

        // that.setState({
        //   recommendedTagArray: response.data.data.Items,
        //   recommendedTagList: optionArray,
        // });
        // that.getPromptsList();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getPromptsList = () => {
    setSpinner(true);
    let filter = "&deactivate=0&archive=0";
    // setPromptList([]);
    Axios.get(`${process.env.REACT_APP_BASE_URL}api/Prompt?pagination=0&type=prompt${filter}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(function (response) {
        console.log("responde", response);
        !modal.id && setSpinner(false);
        let optionArray = response.data.data.map((ele) => {
          return { label: ele.name, value: ele.id };
        });
        setOptions((prev) => ({ ...prev, promptList: optionArray }));
      })
      .catch(function (error) {
        setSpinner(false);
        console.log(error);
        // that?.setState({ preloader: false });
      });
  };

  console.log("prompttttttttttttttttttt", promptArray);
  const handleChangePrompt = (e, index) => {
    setPromptArray((prev) => {
      const updatedArray = [...prev];
      updatedArray[index] = e;
      return updatedArray;
    });

    // let arr = [...promptArray];
    // arr[index] = e;
    // setPromptArray(arr);
    // let arrOptions = [...options.promptList];
    // arrOptions.filter((item, index) => {
    //   if (item.value == e.value) {
    //     arrOptions.splice(index, 1);
    //   }
    // });

    // setOptions((prev) => ({ ...prev, promptList: arrOptions }));
  };
  const addEditProgram = () => {
    setSpinner(true);
    console.log("selectedcat", selectedcat, "selectedTag", selectedTag);
    const tagIds = (selectedTag || []).map((item) => item.value).join(",");
    const catIds = selectedcat.value;

    let obj = {
      ProgramCategory: catIds,
      ProgramTags: tagIds,
      Prompt1: promptArray[0].value,
      Prompt2: promptArray[1].value,
      Prompt3: promptArray[2].value,
      Prompt4: promptArray[3].value,
      Prompt5: promptArray[4].value,
    };
    const meth = modal.type === "edit" ? Axios.put : Axios.post;
    const url = modal.type === "edit" ? "/" + modal.id : "";
    meth(`${process.env.REACT_APP_BASE_URL}api/Program${url}`, obj, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(function (response) {
        console.log("response", response);
        getProgramList(selectedValue);
        setSpinner(false);
        setOptions();
        setSelectedCat("");
        setSelectedTag("");
        setModal({
          show: false,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getProgramDetailsHandle = (id) => {
    setSpinner(true);
    Axios.get(`${process.env.REACT_APP_BASE_URL}api/Program/${id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(function (response) {
        console.log("response111", response.data.data.Prompt1);
        setSpinner(false);
        setSelectedCat({
          label: response.data.data.ProgramCategory.name,
          value: response.data.data.ProgramCategory.id,
        });
        setSelectedTag(
          response.data.data.ProgramTags.length > 0
            ? response.data.data.ProgramTags.map((items, index) => {
                return {
                  label: items.name,
                  value: items.id,
                };
              })
            : []
        );
        setPromptArray([
          {
            label: response.data.data.Prompt1.name,
            value: response.data.data.Prompt1.id,
          },
          {
            label: response.data.data.Prompt2.name,
            value: response.data.data.Prompt2.id,
          },
          {
            label: response.data.data.Prompt3.name,
            value: response.data.data.Prompt3.id,
          },
          {
            label: response.data.data.Prompt4.name,
            value: response.data.data.Prompt4.id,
          },
          {
            label: response.data.data.Prompt5.name,
            value: response.data.data.Prompt5.id,
          },
        ]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log("errors", errors);

  useEffect(() => {
    getRecommendedCategoryList();
    getRecommendedTagList();
    getPromptsList();
  }, []);

  console.log("modal", modal);

  useEffect(() => {
    if (modal.id) {
      getProgramDetailsHandle(modal.id);
    }
  }, [modal.id]);

  useEffect(() => {
    if (
      promptArray[0].label &&
      promptArray[1].label &&
      promptArray[2].label &&
      promptArray[3].label &&
      promptArray[4].label
    ) {
      setErrors((prev) => ({ ...prev, promptArray: "" }));
    }
  }, [promptArray]);

  console.log("optionssssssssssssssssssssssssssssss", options);

  return (
    <div
      className={`modal fade bd-example-modal-sm ${modal.show ? "show" : ""}`}
      style={{ display: `${modal.show ? "block" : "none"}` }}
    >
      <div className="modal-dialog modal-md" style={{ maxWidth: "500px" }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{modal.type === "edit" ? "Update" : "Add New"} Program</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              // onClick={() => {
              //   clearField();
              // }}
              onClick={() => setModal({ show: false })}
            >
              <span>&times;</span>
            </button>
          </div>
          <div className="row modal-body">
            <div className="col-md-12 form-group">
              <div className="mb-2">
                <label htmlFor="example-search-input" className="col-form-label">
                  Recommendation Category
                </label>
                <ReactSelect
                  options={options.categoriesList}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={true}
                  onChange={(e) => {
                    setSelectedCat(e);
                    setErrors((prev) => ({ ...prev, selectedcat: "" }));
                  }}
                  // onBlur={(e) => validateFields("categorySelected")}
                  isSearchable={true}
                  value={selectedcat}
                />
              </div>
              <p style={{ color: "red" }}>{errors.selectedcat ? errors.selectedcat : ""}</p>

              <div className="mb-2">
                <label htmlFor="example-search-input" className="col-form-label">
                  Recommendation Tags
                </label>
                <ReactSelect
                  options={options.tagsList}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={(e) => {
                    setSelectedTag(e);
                    setErrors((prev) => ({ ...prev, selectedTag: "" }));
                  }}
                  // onBlur={(e) => validateFields("tagsSelected")}
                  allowSelectAll={true}
                  isSearchable={true}
                  value={selectedTag}
                />
              </div>
              <p style={{ color: "red" }}>{errors.selectedTag ? errors.selectedTag : ""}</p>

              <div className="mb-2">
                <label htmlFor="example-search-input" className="col-form-label">
                  Program Prompts
                </label>
                <p style={{ color: "red" }}>{errors.promptArray ? errors.promptArray : ""}</p>
                {promptArray.length > 0 &&
                  promptArray?.map((promp, index) => (
                    <Fragment key={index}>
                      <div className="prgramPromp">
                        <span className="numb">{index + 1}</span>
                        <ReactSelect
                          className="reactSel"
                          options={options.promptList.filter(
                            (option) =>
                              !promptArray.some((toRemove) => toRemove.label === option.label)
                          )}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={true}
                          onChange={(e) => handleChangePrompt(e, index)}
                          allowSelectAll={true}
                          isSearchable={true}
                          value={promp || null}
                        />
                      </div>
                    </Fragment>
                  ))}
              </div>
            </div>
          </div>

          <div className="modal-footer justify-content-center">
            <button
              type="button"
              data-dismiss="modal"
              className="btn btn-info"
              onClick={() => {
                validationHandle() && addEditProgram();
              }}
            >
              {modal.type === "edit" ? "Update" : "Submit"}
            </button>
          </div>
          {/* <p>{state.errMessages}</p> */}
        </div>
      </div>
    </div>
  );
}

export default AddEditProg;
