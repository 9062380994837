import Axios from "../../axios/axiosInstance";
import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AppContext from "../../context/AppContext";
import AddEditProg from "./AddEditProg";
import DelProg from "./DelProg";
import Pagination from "../../widgets/Pagination";

function Programs() {
  const [programsArray, setProgramsArray] = useState([]);
  const [filterValue, setFilterValue] = useState([])
  const [ selectedValue, setSelectedValue] = useState()
  const [page, setPage] = useState(1);
  const { spinner, setSpinner } = useContext(AppContext);
  const [errors, setErrors] = useState({});
  const [modal, setModal] = useState({
    show: false,
    type: "",
  });
  const [delModal, setDelModal] = useState({
    show: false,
    id: "",
  });
  const token = JSON.parse(localStorage.getItem("AUTH_USER_TOKEN_KEY"));

  // const onChangeUpdate = (event, field) => {
  //   setState({ [field]: event.target.value });
  // }

  // const validateFields = (field) => {
  //   const errors = { ...state.errors };
  //   let requiredFields = ['categorySelected', 'tagsSelected']

  //   if (field != "") {
  //     if (state[field] != "") {
  //       setState((prevState) => ({
  //         errors: {
  //           ...prevState.errors,
  //           [field]: "",
  //         },
  //       }));
  //     } else {
  //       setState((prevState) => ({
  //         errors: {
  //           ...prevState.errors,
  //           [field]: "This field is required",
  //         },
  //       }));
  //     }
  //   } else {
  //     requiredFields.forEach((field1) => {
  //       if (!state[field1]) {
  //         errors[field1] = "This field is required";
  //       }
  //     });
  //     setState({ errors: errors });
  //   }
  //   return errors;
  // }

  // const clearField = () => {
  //   setState({
  //     isUpdate: false,
  //     addNew: false,
  //     errors: {},
  //     categorySelected: null,
  //     tagsSelected: null,
  //     promptsSelected: [{}, {}, {}, {}, {}]
  //   });
  // }

  // componentDidUpdate() {
  //   if (state.imageTemp && state.audioTemp) {
  //     setState({ preloader: false });
  //     addStoryFlow();
  //   }
  // }

  // componentWillMount() {
  //   window.scroll({
  //     top: 0,
  //     behavior: 'smooth'
  //   });
  //   getProgramsList();
  //   getPromptsList();
  //   getCategoryList();
  //   getTagsList();
  // }

  // const deleteProgram = () => {
  //   let obj = {
  //     method: "deleteProgram",
  //     program_id: state.deleteId,
  //   };
  //   let that = this;
  //   Axios
  //     .post(
  //       "https://ybw8xm9rj0.execute-api.us-east-2.amazonaws.com/v1/getstoryflows2",
  //       obj
  //     )
  //     .then(function (response) {
  //       console.log(response);

  //       if (response.data.message == "Success") {
  //         console.log(response);
  //         that.setState({ ShowDeleteModel: false, deleteId: 0 });

  //         // arrange indexing after element delete
  //         const programList = [...that.state.programsArray];

  //         // remove ele from list
  //         programList.splice(that.state.deleteItemIndex, 1);

  //         // minus 1 from which index element remove
  //         const arrageArray = programList.map((ele, index) => {
  //           if (index >= that.state.deleteItemIndex) {
  //             ele.order_idx -= 1;
  //           }
  //           return ele;
  //         });

  //         that.dragAndDropApiCall(arrageArray)
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }

  const getProgramCategoryList = () => {
    setSpinner(true);
    Axios
      .get(
        `${process.env.REACT_APP_BASE_URL}api/ProgramCategory`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function (response) {
        setSpinner(false);
        setSelectedValue(response.data.data[0].id)
        setFilterValue(response.data.data);
      })
      .catch(function (error) {
        setSpinner(false);
        console.log(error);
      });
  };

  const getProgramList = (id) => {
    console.log("token", token);
    setSpinner(true);
    
    setProgramsArray([]);
    Axios
      .get(`${process.env.REACT_APP_BASE_URL}api/Program/programsByCategory/${id}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        setSpinner(false);
        console.log("response", response);
        setProgramsArray(response.data.data);
      })
      .catch(function (error) {
        setSpinner(false);
        console.log(error);
      });
  };

   // drag and drop function
   const handleDrag = (result) => {
    let tempUser = [...programsArray];
    let [selectedRow] = tempUser.splice(result.source.index, 1);
    tempUser?.splice(result?.destination?.index, 0, selectedRow);
    let newArr = tempUser.map((items, index) => {
      return {
       ...items,
        order_idx: index,
      }
    });
    setProgramsArray(newArr);
    dragAndDropApiCall(newArr);
  };

  const dragAndDropApiCall = (oderItems) => {
    setSpinner(true);
    const obj = {
      data: oderItems,
    };
    Axios
      .put(`${process.env.REACT_APP_BASE_URL}api/Program/dragProgramByCategory`, obj, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        setSpinner(false);
      })
      .catch(function (error) {
        setSpinner(false);
      });
  };


  useEffect(() => {
    if(selectedValue)
    getProgramList(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    getProgramCategoryList()
  },[])

  console.log("selected", selectedValue);
  return (
    <div className="body-bg">
      {/* {state.preloader && (
      <div id="preloader">
        <div className="loader"></div>
      </div>
    )} */}
      <div className="horizontal-main-wrapper">
        {/* <Header _this={this} history={props.history} /> */}
        <div
          className="main-content-inner"
          // onClick={() => setState({ showMenu: false })}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 mt-5">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="listHead">Programs</p>
                    <div className="col-md-2 col-6">
                        <select
                          className="form-control bgSelectCol"
                          value={selectedValue}
                          onChange={(e) => setSelectedValue(e.target.value)}
                        >
                          {filterValue?.map(
                            (category, index) => {
                              return (
                                <option value={category.id}>
                                  {console.log("category", category)}
                                  {category?.name}
                                </option>
                              );
                            }
                          )}
                        </select>
                      </div>
                      <input
                        className="btn btn-info mb-3 ml-auto"
                        data-toggle="modal"
                        data-target=".bd-example-modal-sm"
                        type="submit"
                        value="Add New"
                        // onClick={() => setState({
                        //   addNew: true, errors: {},
                        //   categorySelected: null,
                        //   tagsSelected: null,
                        //   promptsSelected: [{}, {}, {}, {}, {}]
                        // })}
                        onClick={() =>
                          setModal({
                            show: true,
                            type: "add",
                          })
                        }
                      />
                    </div>
                    <div className="single-table">
                      <div className="table-responsive">
                        <DragDropContext
                          onDragEnd={(results) => handleDrag(results)}
                        >
                          <table className="table table-hover progress-table text-center">
                            <thead className="text-uppercase">
                              <tr>
                              <th scope="col">NO</th>
                              <th scope="col">Category</th>
                              <th scope="col">Prompts</th>
                              {/* <th scope="col">POSITION</th> */}
                              <th scope="col">ACTION</th>
                              </tr>
                            </thead>
                            <Droppable droppableId={programsArray[0]}>
                              {(provided) => (
                                <tbody
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  {programsArray?.length ? (
                                    programsArray?.map((data, index) => {
                                      return (
                                        <Draggable
                                          draggableId={"" + data.id}
                                          index={index}
                                          key={data.id}
                                          type="TASK"
                                        >
                                          {(provider) => (
                                            <tr
                                            key={data.id}
                                              ref={provider.innerRef}
                                              {...provider.draggableProps}
                                              {...provider.dragHandleProps}
                                            >
                                            {console.log("data", data)}
      
                                            <th scope="row">
                                              {data ? index + 1 : ""}
                                            </th>
                                            <td>{data.ProgramCategory?.name}</td>
                                            <td>
                                              {data?.Prompt1?.name +
                                                "," +
                                                data?.Prompt2?.name +
                                                "," +
                                                data?.Prompt3?.name +
                                                "," +
                                                data?.Prompt4?.name +
                                                "," +
                                                data?.Prompt5?.name}
                                            </td>
                                            {/* <td>{data ? data.order_idx + 1 : ""}</td> */}
                                            <td>
                                              <ul className="d-flex justify-content-center">
                                                <li>
                                                  <a
                                                    onClick={() => {
                                                      setModal({
                                                        show: true,
                                                        type: "edit",
                                                        id: data.id,
                                                      });
                                                      // setCategory(data.name);
                                                    }}
                                                  >
                                                    <span
                                                      className="status-p "
                                                      data-toggle="modal"
                                                      data-target="#exampleModalCenter2"
                                                    >
                                                      <i className="fa fa-pencil"></i>
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    onClick={() =>
                                                      setDelModal({
                                                        show: true,
                                                        id: data.id,
                                                      })
                                                    }
                                                  >
                                                    <span
                                                      className="status-p "
                                                      data-toggle="modal"
                                                      data-target="#exampleModalCenter2"
                                                    >
                                                      <i className="fa fa-trash"></i>
                                                    </span>
                                                  </a>
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>
                                          )}
                                        </Draggable>
                                      );
                                    })
                                  ) : (!spinner &&
                                    <tr>
                                      <td colspan="4">{"No Result found!"}</td>
                                    </tr>
                                  )}
                                </tbody>
                              )}
                            </Droppable>
                          </table>
                        </DragDropContext>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Pagination
          totalPages={programsArray?.last_page}
          currentPage={programsArray?.current_page}
          page={page}
          setPage={setPage}
        /> */}

        {modal.show && (
          <AddEditProg
            modal={modal}
            setModal={setModal}
            getProgramList={getProgramList}
            errors={errors}
            setErrors={setErrors}
            page={page}
            selectedValue={selectedValue}
          />
        )}

        {delModal.show && (
          <DelProg
            id={delModal.id}
            delModal={delModal}
            setDelModal={setDelModal}
            getProgramList={getProgramList}
            page={page}
            selectedValue={selectedValue}
          />
        )}
      </div>
    </div>
  );
}

export default Programs;
