import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "./Header";

function DashboardLayout() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("AUTH_USER_TOKEN_KEY")) {
      navigate("/");
    }
  }, [localStorage.getItem("AUTH_USER_TOKEN_KEY")]);
  return (
    <div>
      <Header />
      <Outlet />
      <footer>
        <div className="footer-area">
          <p>
            © Copyright 2024. All right reserved. <a href="#">humanStreams</a>.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default DashboardLayout;
