import React, { useContext, useState } from "react";
import AppContext from "../../context/AppContext";
import Axios from "../../axios/axiosInstance";

function AddCategory({ modal, setModal, category, setCategory, getCategoryList, description, setDescription,dailyCat,setDailyCat }) {
  const [error, setError] = useState();
  const token = JSON.parse(localStorage.getItem("AUTH_USER_TOKEN_KEY"));
  const { setSpinner } = useContext(AppContext);
  var noBlankOrEmptyPattern = /^(?!\s*$).+/;

  const validateField = () => {
    if (!category || !noBlankOrEmptyPattern.test(category)) {
      setError("This field is required");
    } else {
      addEditCategory();
    }
  };

  console.log("dailyCat",dailyCat);

  const clearFields = () => {
    setModal({});
    setCategory("");
    setError("");
    setDescription("")
  };

  const addEditCategory = () => {
    setSpinner(true);
    let obj = {
      name: category,
      description: description,
      daily: dailyCat 
    };
    const meth = modal.type === "edit" ? Axios.put : Axios.post;
    const url = modal.type === "edit" ? "/" + modal.id : "";
    console.log(Axios.p);
    meth(`${process.env.REACT_APP_BASE_URL}api/PromptCategory${url}`, obj, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(function (response) {
        setSpinner(false);
        clearFields();
        getCategoryList();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div
      className={`modal fade bd-example-modal-sm ${modal.show ? "show" : ""}`}
      style={{ display: `${modal.show ? "block" : "none"}` }}
    >
      <div className="modal-dialog modal-md" style={{ maxWidth: "500px" }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{modal.type === "edit" ? "Update" : "Add New"} Category</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => clearFields()}
            >
              <span>&times;</span>
            </button>
          </div>
          <div className="row modal-body">
            <div className="col-md-12 form-group">
              <div className="mb-2">
                <label htmlFor="example-search-input" className="col-form-label">
                  Category Name
                </label>
                <input
                  value={category}
                  name="category"
                  onChange={(e) => {
                    setError("");
                    setCategory(e.target.value);
                  }}
                  // onChange={(e) => this.onChangeUpdate(e, "name")}
                  // onBlur={(e) => this.validateFields("name")}
                  className="form-control"
                  type="text"
                  id="name"
                />
              </div>
              <p style={{ color: "red" }}>{error ? error : ""}</p>
            </div>
            <div className="col-md-12 form-group">
              <div className="mb-2">
                <label htmlFor="example-search-input" className="col-form-label">
                  Description
                </label>
                <textarea
                  value={description}
                  name="description"
                  onChange={(e) => {
                    // setError("");
                    if (e.target.value.length <= 100) {
                      setDescription(e.target.value);
                    }
                    else {
                      return
                    }
                  }}
                  // onChange={(e) => this.onChangeUpdate(e, "name")}
                  // onBlur={(e) => this.validateFields("name")}
                  className="form-control"
                  type="textarea"
                  id="name"
                />
              </div>
              <p style={{ color: "red" }}>{error ? error : ""}</p>
            </div>
            <div className="col-md-12 form-group">
              <div className="mb-2">
                <input type="checkbox"
                  value={dailyCat}
                  name="description"
                  onChange={(e) => setDailyCat(e.target.checked)}
                  checked={dailyCat}
                  className="daily_CustomCheckbox"
                  id="name" />

                <label for="wp-comment-cookies-consent"> Daily Category
                </label>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-center">
            <button
              type="button"
              data-dismiss="modal"
              className="btn btn-info"
              onClick={() => {
                //   if (this.state.isUpdate) {
                //     this.updateStory();
                //   } else {
                //     this.uploadMedias();
                //   }
                // this.addCategory();
                validateField();
              }}
            >
              {modal.type === "edit" ? "Update" : "Submit"}
            </button>
          </div>
          {/* <p>{this.state.errMessages}</p> */}
        </div>
      </div>
    </div>
  );
}

export default AddCategory;
