import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import AppContext from "../../context/AppContext";
import Axios from "../../axios/axiosInstance";
import VideoModal from "./VideoModal";

import placeholderImg from "../../assets/image/no_image.png";
import girlsIcon from "../../assets/image/girls.png";
import playIcon from "../../assets/image/PlayIc.png";
import AudioplayIcon from "../../assets/image/play.png";
import AudiopauseIcon from "../../assets/image/Pause_btn22.png";
import appStore from "../../assets/image/App_store.png";
import noDataImg from "../../assets/image/Rectangle_msg.png";
import backgroundImg from "../../assets/image/bgviewstream.png";

function ViewShared() {
  const [data, setData] = useState();
  const [show, setShow] = useState({ show: false, data: "" });
  const [noData, setNoData] = useState(false);

  const { id } = useParams();
  const { setSpinner } = useContext(AppContext);

  const getPromptsList = () => {
    setSpinner(true);

    Axios.get(`${process.env.REACT_APP_BASE_URL}api/Stream/${id}`)
      .then(async function (response) {
        setData(response.data.data);

        setSpinner(false);
      })
      .catch(function (error) {
        setSpinner(false);
        if (error.response.status == 404) {
          setNoData(true);
        }
        console.log("errorrrrrrrrrrrrrrrrrr", error.response.status);
        // that?.setState({ preloader: false });
        setSpinner(false);
      });
  };

  useEffect(() => {
    getPromptsList();
  }, []);

  const handleLoadedMetadata = (e, id) => {
    console.log("testingggggggg", e.target.duration);
    if (!isNaN(e.target.duration) && id) {
      setData((prevData) => ({
        ...prevData,
        StreamBubbles: prevData.StreamBubbles.map((items) => {
          if (items.bubble_type === 3 && items.id === id) {
            console.log("items.id === id", items.id, id);
            return {
              ...items,
              duration: e.target.duration,
            };
          } else {
            return items; // Return unchanged items when the condition doesn't match
          }
        }),
      }));
    }
  };

  const handleTimeUpdate = (e, id) => {
    setData({
      ...data,
      StreamBubbles: data.StreamBubbles.map((items) => {
        if (items.id === id) {
          return {
            ...items,
            currentTime: e.target.currentTime,
          };
        } else {
          return items; // Return unchanged items when the condition doesn't match
        }
      }),
    });
    // setCurrentTime(e.target.currentTime);
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };

  const togglePlayPause = (index, id) => {
    const audio = document.getElementById(`audioid12${index}`);

    setData({
      ...data,
      StreamBubbles: data.StreamBubbles.map((items) => {
        if (items.id === id) {
          if (items.play) {
            if (!audio.paused) {
              audio.pause();
            }
          } else {
            if (audio.paused) {
              audio.play().catch((error) => {
                console.error("Failed to play audio:", error);
              });
            }
          }
          return {
            ...items,
            play: !items.play,
          };
        } else {
          return items; // Return unchanged items when the condition doesn't match
        }
      }),
    });
  };

  function convertDate(originalDate) {
    // Split the original date string
    const [datePart, timePart] = originalDate?.split("T");
    const [year, month, day] = datePart?.split("-");

    // Define an array to map month numbers to month names
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Get the month name from the array
    const monthName = months[parseInt(month) - 1]; // Adjust month to 0-indexed

    // Construct the desired format
    const formattedDate = monthName + " " + parseInt(day, 10) + ", " + year;

    return formattedDate;
  }

  let imageRef = useRef();

  const shouldFetchThumbImage = data?.StreamBubbles.some(
    (item) => item.bubble_type === 6 && !item.thumb_image
  );

  const getMetaContentHandler = (link, index) => {
    let obj = {
      url: ensureHttps(link),
      method: "getMetaFromURL",
    };

    return Axios.post(
      "https://ybw8xm9rj0.execute-api.us-east-2.amazonaws.com/v1/getstoryflows2",
      obj
    )
      .then(function (response) {
        const ogImage = response.data.data["og:image"];
        return ogImage;
      })
      .catch(function (error) {
        console.error("Error fetching meta data:", error);
        throw error;
      });
  };

  const updateThumbImages = async () => {
    try {
      if (!data || !data.StreamBubbles) return;

      const modifiedBubbles = await Promise.all(
        data.StreamBubbles.map(async (items) => {
          if (items?.bubble_type === 6) {
            const thumbImage = await getMetaContentHandler(items.bubble_detail);
            return {
              ...items,
              thumb_image: thumbImage,
            };
          }
          return items;
        })
      );

      console.log("modifiedBubbl", modifiedBubbles);

      setData((prev) => ({
        ...prev,
        StreamBubbles: modifiedBubbles,
      }));
    } catch (error) {
      console.error("Error updating thumb_image:", error);
    }
  };
  useEffect(() => {
    if (
      shouldFetchThumbImage &&
      data?.StreamBubbles?.filter((item) => item.bubble_type === 3).every(
        (item) => item.hasOwnProperty("duration")
      )
    ) {
      console.log("thumbbbbbbbbbbbb");
      updateThumbImages();
    }
  }, [
    shouldFetchThumbImage,
    data?.StreamBubbles?.filter((item) => item.bubble_type === 3).every(
      (item) => item.hasOwnProperty("duration")
    ),
  ]);

  function ensureHttps(url) {
    if (!url.startsWith("https://")) {
      return "https://" + url;
    }
    return url;
  }

  // useEffect(() => {
  //   console.log('Component mounted');
  //   return () => {
  //     console.log('Component unmounted');
  //   };
  // }, []);

  function renderTextWithLineBreaks(text) {
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  }
  let prevDate;

  const backgroundImageUrl =
    !noData && data?.colorTheme
      ? `url(${
          data?.colorTheme
        }`
      : "";

  return (
    <>
      <div
        className={`${noData ? "streamView" : ""}`}
        style={{
          backgroundImage: backgroundImageUrl,
          backgroundRepeat: "no-repeat",
          // backgroundSize: "contain",
          backgroundPosition: "center center",
          minHeight: "100vh",
          height: "100%",
          position: "fixed",
          width: "100%",
          overflow: "hidden",
          backgroundSize: "100% 100%",
        }}
      >
        <div className={`stream-body ${noData ? "full-screen" : ""}`}>
          <h1 className="streamView_heading">{data?.stream_name}</h1>
          {/* {data?.created_at && (
            <div className="data-center">
              <p className="stream_date">
                {convertDate(data?.created_at ? data?.created_at : "")}
              </p>
            </div>
          )} */}

          <div className="stream-info">
            {data?.StreamBubbles?.map((items, index) => {
              const today = new Date();
              const yearT = today.getFullYear();
              const monthT = String(today.getMonth() + 1).padStart(2, "0"); // January is 0
              const dayT = String(today.getDate()).padStart(2, "0");
              const formattedDateT = `${yearT}-${monthT}-${dayT}`;
              const date = new Date(items.created_at);
              const year = date.getFullYear();
              const month = String(date.getMonth() + 1).padStart(2, "0");
              const day = String(date.getDate()).padStart(2, "0");
              const formattedDate = `${year}-${month}-${day}`;
              const showDate = formattedDate !== prevDate;
              if (formattedDate !== prevDate) {
                prevDate = formattedDate;
              }
              return (
                <>
                  {showDate && (
                    <div className="data-center">
                      <p className="stream_date">
                        {formattedDateT == formattedDate
                          ? "Today"
                          : convertDate(formattedDate ? formattedDate : "")}
                      </p>
                    </div>
                  )}
                  {items.bubble_type === 8 ? (
                    <div className="position-relative promt_video">
                      {/* <img src={dotsIcon} alt="" className="dots-img" /> */}
                      <div className="stream-promt">
                        <div className="stream-promt-inner position-relative">
                          <img
                            src={
                              items.thumb_image
                                ? items.thumb_image
                                : items.bubble_detail.split("$")[2]
                            }
                            alt=""
                            className="promt_video_img"
                            style={{ borderRadius: "5px" }}
                          />

                          {/* <img
                          src={playSmallBtn}
                          alt=""
                          className="video-icon-img"
                        /> */}
                        </div>

                        <div className="ml-4 ">
                          <p className="prompt-text">Prompt:</p>
                          <p className="prompt-text">
                            {items.bubble_detail.split("$")[0]}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {items.bubble_type === 7 ? (
                    <div className="position-relative promt_video">
                      {/* <img src={dotsIcon} alt="" className="dots-img" /> */}
                      <div className="stream-promt">
                        <div className="stream-promt-inner position-relative">
                          <img
                            src={
                              items.thumb_image
                                ? items.thumb_image
                                : items.bubble_detail.split("$")[2]
                            }
                            alt=""
                            className="promt_video_img"
                            style={{ borderRadius: "5px" }}
                          />

                          {/* <img
                          src={playSmallBtn}
                          alt=""
                          className="video-icon-img"
                        /> */}
                        </div>

                        <div className="ml-4">
                          <p className="prompt-text">Story Flow:</p>
                          <p className="prompt-text">
                            {items.bubble_detail.split("$")[0]}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {items.bubble_type === 5 ? (
                    <div className="stream-data">
                      {/* <img src={dotsIcon} alt="" className="dots-img" /> */}
                      <p>{renderTextWithLineBreaks(items.bubble_detail)}</p>
                    </div>
                  ) : (
                    ""
                  )}
                  {items.bubble_type === 1 ? (
                    <div className="stream-info">
                      {/* {items?.created_at && (
                        <>
                          {convertDate(items?.created_at) ===
                            convertDate(data?.created_at) ? (
                            ""
                          ) : (
                            <div className="data-center">
                              <p className="stream_date">
                                {convertDate(items?.created_at)}
                              </p>
                            </div>
                          )}
                        </>
                      )} */}

                      <div className="streamImage">
                        <img
                          ref={imageRef}
                          src={
                            items.bubble_detail
                              ? items.bubble_detail
                              : placeholderImg
                          }
                          alt=""
                          className="content-img"
                        />
                        {/* <img src={dotsIcon} alt="" className="dots-img" /> */}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {items.bubble_type === 4 ? (
                    <div className="stream-info">
                      {/* {items?.created_at && (
                        <>
                          {convertDate(items?.created_at) ===
                            convertDate(data?.created_at) ? (
                            ""
                          ) : (
                            <div className="data-center">
                              <p className="stream_date">
                                {convertDate(items?.created_at)}
                              </p>
                            </div>
                          )}
                        </>
                      )} */}
                      <div className="streamImage">
                        <img
                          src={items.bubble_detail}
                          alt=""
                          className="content-img"
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {items.bubble_type === 3 ? (
                    <div className="stream-info">
                      {/* {items?.created_at && (
                        <>
                          {convertDate(items?.created_at) ===
                            convertDate(data?.created_at) ? (
                            ""
                          ) : (
                            <div className="data-center">
                              <p className="stream_date">
                                {convertDate(items?.created_at)}
                              </p>
                            </div>
                          )}
                        </>
                      )} */}
                      <div
                        className="stream-audio-data"
                        id="audio-player-container"
                      >
                        <p className="text-center audio_name">
                          {items?.url_title}
                        </p>
                        <audio
                          id={`audioid12${index}`}
                          src={items.bubble_detail}
                          controls
                          onLoadedMetadata={(e) =>
                            handleLoadedMetadata(e, items.id)
                          }
                          onTimeUpdate={(e) => handleTimeUpdate(e, items.id)}
                          style={{ display: "none" }}
                        ></audio>
                        <div className="audio-player">
                          <button
                            id="play-btn12"
                            title="play"
                            className="play-btn"
                          >
                            <img
                              id="icon12"
                              src={items.play ? AudiopauseIcon : AudioplayIcon}
                              alt=""
                              onClick={() => togglePlayPause(index, items.id)}
                            />
                          </button>
                          <span
                            id="current-time12"
                            className="time current-time"
                          >
                            {formatTime(
                              items.currentTime ? items.currentTime : 0
                            )}
                          </span>
                          <input
                            type="range"
                            readonly=""
                            className="slider"
                            id="seek-slider12"
                            max={items.duration ? items.duration : 0}
                            value={items.currentTime ? items.currentTime : 0}
                          />

                          <span id="duration12" className="time duration">
                            {formatTime(items.duration)}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {items.bubble_type === 2 ? (
                    <div className="stream-info">
                      {/* {items.created_at && (
                        <>
                          {convertDate(items.created_at) !==
                            convertDate(data?.created_at) && (
                              <div className="data-center">
                                <p className="stream_date">
                                  {convertDate(items.created_at)}
                                </p>
                              </div>
                            )}
                        </>
                      )} */}

                      <div className="streamImage">
                        <img
                          src={
                            items.thumb_image ? items?.thumb_image : girlsIcon
                          }
                          alt=""
                          className="content-img"
                        />

                        <img
                          src={playIcon}
                          alt=""
                          className="playBtnImg"
                          id="myBtn"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setShow({ data: items.bubble_detail, show: true })
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {items.bubble_type === 6 ? (
                    <div className="stream-info">
                      <div className="stream-data p-1">
                        <img
                          src={
                            items?.thumb_image?.startsWith("https://")
                              ? items?.thumb_image
                              : placeholderImg
                          }
                          alt=""
                          className="hyperLinkimg"
                        />
                        <div className="stream-info-footer text-center">
                          <button
                            // className="stream-info"
                            style={{
                              border: "none",
                              borderRadius: "20px",
                              background: "white",
                            }}
                            onClick={() =>
                              window.open(
                                `${ensureHttps(items.bubble_detail)}`,
                                "_blank"
                              )
                            }
                          >
                            <h1 className="hyperLinkHeading">
                              {items.url_title}
                            </h1>
                            {/* <Link to="#" className="hyperLinkText"> */}
                            <p style={{ fontSize: "14px" }}>
                              {items.bubble_detail}
                            </p>
                            {/* </Link> */}
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
          </div>
          {noData && (
            // <img src={noDataImg} alt="no-data">
            //   {/* <img src={deletedRef} alt="deleted-text"/> */}
            //   </img>
            <div className="trsparnt">This reflection has been deleted.</div>
          )}
          <img
            src={appStore}
            alt="img"
            // style={{width:"180px", height:"68px"}}
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open("https://itunes.apple.com/app/id1540277909", "_blank")
            }
          />
        </div>
      </div>
      {/* {console.log('ek bari or',show)}
        <VideoModal show={show} setShow={setShow} data={show.data} /> */}
      {show.show && (
        <VideoModal show={show} setShow={setShow} data={show.data} />
      )}
    </>
  );
}

export default ViewShared;
