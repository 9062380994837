import moment from 'moment'
import React from 'react'

function FeedbackModal({modal, setModal, ratingList}) {

  const getStarClass = (rating, ele) => {
    if (ele <= rating) {
      return 'fa fa-star';
    }
    else if (ele > rating && ele - rating < 1) {
      return 'fa fa-star-half-o';
    }
    else {
      return 'fa fa-star-o';
    }
  }
  console.log("modalDta",modal);
  return (
    <div
        className={`modal fade bd-example-modal-sm ${modal.show ? "show" : ""
          }`}
        style={{ display: `${modal.show ? "block" : "none"}` }}
      >
        <div
          className="modal-dialog modal-md"
          style={{ maxWidth: "500px", marginTop: "180px" }}
        >
          <div className="modal-content" style={{ borderRadius: "20px" }}>
            <div className="modal-header border-0 pb-2">
              <button
                type="button"
                style={{ zIndex: "999" }}
                className="close"
                data-dismiss="modal"
                // onClick={() => setState({ showModal: false, viewFeedBack: {} })}
                onClick={() => setModal({
                  show:false
                })}
              >
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body mbod">
              <div className="msgTitle">MESSAGE</div>

              <div className="msgDiv">
                <div className="d-flex justify-content-between">
                  <div><strong>Name : &nbsp;</strong>{modal.data?.username}</div>
                  <div><strong>Date : &nbsp;</strong>{moment(modal.data?.viewFeedBack?.updated_at).format('MM/DD/YYYY')}</div>
                </div>
                <hr></hr>
                <div className="rateDiv">
                  <h2>{modal.data?.viewFeedBack?.rating}</h2>
                  <span className="starRate d-block" >
                    {ratingList?.map(el => <i className={'fa-lg ' + getStarClass(modal.data?.rating, el)} style={{ paddingRight: "2px" }}></i>)}
                  </span>
                </div>
                <p className="text-justify">{modal.data?.message}</p>
              </div>

            </div>
          </div>
        </div>
      </div>
  )
}

export default FeedbackModal
