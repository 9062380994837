import Axios from '../../axios/axiosInstance';
import React, { useContext, useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import AppContext from '../../context/AppContext';
import AddEditProgTags from './AddEditProgTags';
import DelCatTag from './DelCatTag';

function ProgramTags() {
  const [tagsArray, setTagsArray] = useState([]);
  const { spinner,setSpinner } = useContext(AppContext)
  const [modal, setModal] = useState({
    show:false,
    type:""
  })
  const [delModal, setDelModal] = useState({
    show:false,
    id:""
  })
  const [name, setName] = useState();
  const token = JSON.parse(localStorage.getItem("AUTH_USER_TOKEN_KEY"));


  // const onChangeUpdate = (event, field) => {
  //   setState({ [field]: event.target.value });
  // }

  // const validateFields = (field) => {
  //   const errors = { ...state.errors };
  //   let requiredFields = ["name"];
  //   if (field != "") {
  //     if (state[field].trim() != "") {
  //       setState((prevState) => ({
  //         errors: {
  //           ...prevState.errors,
  //           [field]: "",
  //         },
  //       }));
  //     } else {
  //       setState((prevState) => ({
  //         errors: {
  //           ...prevState.errors,
  //           [field]: "This field is required",
  //         },
  //       }));
  //     }
  //   } else {
  //     requiredFields.forEach((field1) => {
  //       if (!state[field1]) {
  //         errors[field1] = "This field is required";
  //       }
  //     });
  //     setState({ errors: errors });
  //   }
  //   return errors;
  // }

  // const clearField = () => {
  //   document.getElementById("name").value = "";
  //   setState({
  //     isUpdate: false,
  //     addNew: false,
  //     errors: {},
  //     name: "",
  //   });
  // }

  // componentDidUpdate() {
  //   if (state.imageTemp && state.audioTemp) {
  //     setState({ preloader: false });
  //     addStoryFlow();
  //   }
  // }

  // componentWillMount() {
  //   window.scroll({
  //     top: 0,
  //     behavior: 'smooth'
  //   });
  //   getTagsList();
  // }

  const deleteProgramTag = () => {
    setSpinner(true);
    Axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}api/ProgramTag/${delModal.id}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function (response) {
        console.log(response);
        setDelModal({
          show: false,
          id: "",
        });
        setSpinner(false);
        getTagsList();
      })
      .catch(function (error) {
        // console.log("error",error);
        // setError(error.message);
        console.log(error);
      });
  };
  const getTagsList = () => {
    setSpinner(true)
    let obj = {
      method: "getProgramTags",
    };

    let that = this;
    // that.setState({ preloader: true });
    Axios
    .get(
      `${process.env.REACT_APP_BASE_URL}api/ProgramTag?page=1&limit=10`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then(function (response) {
        setSpinner(false);
        setTagsArray(response.data.data)
      })
      .catch(function (error) {
        setSpinner(false);
        console.log(error);
        // that.setState({ preloader: false });
      });
  }

   // drag and drop function
   const handleDrag = (result) => {
    let tempUser = [...tagsArray];
    let [selectedRow] = tempUser.splice(result.source.index, 1);
    tempUser?.splice(result?.destination?.index, 0, selectedRow);
    let newArr = tempUser.map((items, index) => {
      return {
       ...items,
        order_idx: index,
      }
    });
    setTagsArray(newArr);
    dragAndDropApiCall(newArr);
  };

  const dragAndDropApiCall = (oderItems) => {
    setSpinner(true);
    const obj = {
      data: oderItems,
    };
    Axios
      .put(`${process.env.REACT_APP_BASE_URL}api/ProgramTag`, obj, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        setSpinner(false);
      })
      .catch(function (error) {
        setSpinner(false);
      });
  };

  useEffect(() => {
    getTagsList()
  },[])
  return (
    <div className="body-bg">
        <div className="horizontal-main-wrapper">
          <div
            className="main-content-inner"
            // onClick={() => setState({ showMenu: false })}
          >
            <div className="container">
              <div className="row">
                <div className="col-12 mt-5">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="listHead">
                          Program Tags
                        </p>
                        <input
                          className="btn btn-info mb-3 ml-auto"
                          data-toggle="modal"
                          data-target=".bd-example-modal-sm"
                          type="submit"
                          value="Add New"
                          onClick={() => setModal({
                            show:true,
                            type:"add"
                          })}
                        />
                      </div>
                      <div className="single-table">
                        <div className="table-responsive">
                        <DragDropContext
                          onDragEnd={(results) => handleDrag(results)}
                        >
                          <table className="table table-hover progress-table text-center">
                            <thead className="text-uppercase">
                              <tr>
                                <th scope="col">NO</th>
                                <th scope="col">TAG NAME</th>
                                <th scope="col">POSITION</th>
                                <th scope="col">ACTION</th>
                              </tr>
                            </thead>
                            <Droppable droppableId={tagsArray[0]}>
                              {(provided) => (
                                <tbody
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  {tagsArray?.length ? (
                                    tagsArray?.map((data, index) => {
                                      return (
                                        <Draggable
                                          draggableId={"" + data.id}
                                          index={index}
                                          key={data.id}
                                          type="TASK"
                                        >
                                          {(provider) => (
                                            <tr
                                              key={data.id}
                                              ref={provider.innerRef}
                                              {...provider.draggableProps}
                                              {...provider.dragHandleProps}
                                            >
                                              {console.log("data", data)}

                                              <th scope="row">
                                                {data ? index + 1 : ""}
                                              </th>
                                              <td>{data.name}</td>
                                              <td>
                                                {data ? data.order_idx + 1 : ""}
                                              </td>
                                              <td>
                                                <ul className="d-flex justify-content-center">
                                                  <li>
                                                    <a
                                                      onClick={() => {
                                                        setModal({
                                                          show: true,
                                                          type: "edit",
                                                          id: data.id,
                                                        });
                                                        setName(data.name);
                                                      }}
                                                    >
                                                      <span
                                                        className="status-p "
                                                        data-toggle="modal"
                                                        data-target="#exampleModalCenter2"
                                                      >
                                                        <i className="fa fa-pencil"></i>
                                                      </span>
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      onClick={() =>
                                                        setDelModal({
                                                          show: true,
                                                          id: data.id,
                                                        })
                                                      }
                                                    >
                                                      <span
                                                        className="status-p "
                                                        data-toggle="modal"
                                                        data-target="#exampleModalCenter2"
                                                      >
                                                        <i className="fa fa-trash"></i>
                                                      </span>
                                                    </a>
                                                  </li>
                                                </ul>
                                              </td>
                                            </tr>
                                          )}
                                        </Draggable>
                                      );
                                    })
                                  ) : (!spinner &&
                                    <tr>
                                      <td colspan="4">{"No Result found!"}</td>
                                    </tr>
                                  )}
                                </tbody>
                              )}
                            </Droppable>
                          </table>
                        </DragDropContext>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <AddEditProgTags
            modal={modal}
            setModal={setModal}
            name={name}
            setName={setName}
            getTagsList={getTagsList}
          />

        <DelCatTag
          show={delModal}
          setShow={setDelModal}
          deleteApi={deleteProgramTag}
          name={"Tag"}
        />
        </div>
        {/* <Footer /> */}
      </div>
  )
}

export default ProgramTags
