import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Axios from "../../axios/axiosInstance";
import AppContext from "../../context/AppContext";
import DelCatModal from "../category/DelCatModal";
import DeleteModal from "../tags/DeleteModal";
import DelPopularPrompt from "./DelPopularPrompt";

function PopularPrompts() {
  const [categoryArray, setCategoryArray] = useState([]);
  
  const [delModal, setDelModal] = useState({
    show: false,
    id: "",
  });

  const { spinner, setSpinner } = useContext(AppContext);
  const token = JSON.parse(localStorage.getItem("AUTH_USER_TOKEN_KEY"));

  const getCategoryList = () => {
    setSpinner(true);
    Axios
      .get(
        `${process.env.REACT_APP_BASE_URL}api/Prompt/popularPromptAdmin`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function (response) {
        setSpinner(false);
        setCategoryArray(response.data.data);
      })
      .catch(function (error) {
        setSpinner(false);
        console.log(error);
      });
  };

  console.log("category", categoryArray);

  // drag and drop function
  const handleDrag = (result) => {
    // setCategoryArray([]);
    let tempUser = [...categoryArray];
    let [selectedRow] = tempUser.splice(result.source.index, 1);
    tempUser?.splice(result?.destination.index, 0, selectedRow);
    let newArr = tempUser.map((items, index) => {
      return {
       ...items,
       popular_order_idx: index,
      }
    });
    let arr = tempUser.map((items, index) => {
      return {
       id: items.id,
       popular_order_idx: index,
      }
    });
    setCategoryArray(newArr);
    dragAndDropApiCall(arr);
  };

  const dragAndDropApiCall = (oderItems) => {
    setSpinner(true);
    const obj = {
      data: oderItems,
    };
    Axios
      .put(`${process.env.REACT_APP_BASE_URL}api/Prompt/dragPopularPrompt`, obj, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        setSpinner(false);
        // getCategoryList()
      })
      .catch(function (error) {
        setSpinner(false);
      });
  };


  useEffect(() => {
    getCategoryList();
  }, []);

  console.log("deleteModal", delModal);
  return (
    <div className="body-bg">
      <div className="horizontal-main-wrapper">
        {/* <Header _this={this} history={props.history} /> */}
        <div
          className="main-content-inner"
          // onClick={() => setState({ showMenu: false })}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 mt-5">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="listHead">Most Popular Prompts</p>
                      {/* <input
                        className="btn btn-info mb-3 ml-auto"
                        data-toggle="modal"
                        data-target=".bd-example-modal-sm"
                        type="submit"
                        value="Add New"
                        onClick={() =>
                          setModal({
                            show: true,
                            type: "add",
                          })
                        }
                      /> */}
                    </div>
                    <div className="single-table">
                      <div className="table-responsive">
                        <DragDropContext
                          onDragEnd={(results) => handleDrag(results)}
                        >
                          <table className="table table-hover progress-table text-center">
                            <thead className="text-uppercase">
                              <tr>
                                <th scope="col" className="">NO</th>
                                <th scope="col" style={{whiteSpace:"nowrap"}}>Prompt Name</th>
                                <th scope="col">Category Name</th>
                                <th scope="col">POSITION</th>
                                <th scope="col">Description</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <Droppable droppableId={categoryArray[0]}>
                              {(provided) => (
                                <tbody
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  {categoryArray?.length ? (
                                    categoryArray?.map((data, index) => {
                                      return (
                                        <Draggable
                                          draggableId={"" + data.id}
                                          index={index}
                                          key={data.id}
                                          type="TASK"
                                        >
                                          {(provider) => (
                                            <tr
                                              key={data.id}
                                              ref={provider.innerRef}
                                              {...provider.draggableProps}
                                              {...provider.dragHandleProps}
                                            >
                                                {console.log("datadatadata",data)}
                                              <th scope="row">
                                                {data ? index + 1 : ""}
                                              </th>
                                              <td>{data.name}</td>
                                              <td className="desc_td">
                                                {/* {data ? data.order_idx + 1 : ""} */}
                                                {/* {data.description} */}
                                                {data.PromptCategories.length>0 && data.PromptCategories[0].name }
                                                {data.StoryFlowCategories.length>0 && data.StoryFlowCategories[0].name }
                                              </td>
                                              <td>
                                                {/* {data ? data.order_idx  : ""} */}
                                                {index+1}
                                              </td>
                                             
                                              <td>
                                                
                                                {data.description}

                                              </td>
                                              <td>
                                              <ul className="d-flex justify-content-center">
                                          <li>
                                            {console.log("data", data)}
                                            <a
                                              href={
                                                process.env
                                                  .REACT_APP_MEDIA_URL + 
                                                data.image
                                              }
                                              target="_blank"
                                            >
                                              <span className="status-p ">
                                                <i className="fa fa-eye"></i>
                                              </span>
                                            </a>
                                            {console.log(
                                              "stringprogramTagIds",
                                              data
                                            )}
                                          </li>
                                          <li>
                                            <a
                                              href={
                                                process.env
                                                  .REACT_APP_MEDIA_URL +
                                                data.audio
                                              }
                                              target="_blank"
                                            >
                                              <span className="status-p ">
                                                <i className="fa fa-play"></i>
                                              </span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                {setDelModal({
                                                  show: true,
                                                  id: data.id,
                                                  data:data
                                                })
                                                // addEditPromptHandle()
                                            }
                                              }
                                            >
                                              <span
                                                className="status-p "
                                                data-toggle="modal"
                                                data-target="#exampleModalCenter2"
                                              >
                                                <i className="fa fa-trash"></i>
                                              </span>
                                            </a>
                                          </li>
                                          </ul>
                                              </td>
                                            </tr>
                                          )}
                                        </Draggable>
                                      );
                                    })
                                  ) : ( !spinner &&
                                    <tr>
                                      <td colspan="4">{"No Result found!"}</td>
                                    </tr>
                                  )}
                                </tbody>
                              )}
                            </Droppable>
                          </table>
                        </DragDropContext>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {modal.show && (
          <AddCategory
            modal={modal}
            setModal={setModal}
            category={category}
            setCategory={setCategory}
            getCategoryList={getCategoryList}
            description={description} 
            setDescription={setDescription}
            dailyCat={dailyCat}
            setDailyCat={setDailyCat}
          />
        )}
         */}
        {delModal.show && (
          <DelPopularPrompt
            delModal={delModal}
            setDelModal={setDelModal}
            getCategoryList={getCategoryList}
          />
        )}
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default PopularPrompts;
