import React from "react";
// import deleteIcon from "../../assets/image/delete.svg"

function ActiveArchiveModal({ modal, setModal, updatePromptStatus}) {
  return (
    <div
      className={`modal fade bd-example-modal-sm ${modal.show ? "show" : ""}`}
      style={{
        display: `${modal.show ? "block" : "none"}`,
      }}
      id="exampleModalCenter2"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-sm"
        role="document"
      >
        <div className="modal-content">
          <div className="login-area p-0">
            <div className="login-box d-block  text-center">
              <form className="m-0">
                <div className="login-form-head">
                  <img src="./assets/image/delete.svg" alt="" />
                </div>
                <div className="login-form-body">
                  <div className="">
                    {/* <h4 className="pop_up_text">
                        Are you sure you want to {state.selectedStatus === 'archive' ? state.isArchivePrompt ? 'un-archive' : 'archive' : state.isDeletedPrompt ? 'restore' : 'de-activate'} this prompt ?
                      </h4> */}
                    {modal.type === "archive" ? (
                      <h4 className="pop_up_text">
                        Are you sure you want to 
                        {modal.archive === true ? " un-archive " : ' archive '}
                        this prompt ?
                      </h4>
                    ) : (
                      <h4 className="pop_up_text">
                        Are you sure you want to
                        {modal.deactivate === true ? " restore " : ' de-activate '}
                        this prompt ?
                      </h4>
                    )}
                  </div>

                  <div className="submit-btn-area mt-4 d-flex">
                    <a
                      onClick={() =>
                        setModal({
                          show: false,
                          type: "",
                        })
                      }
                      id="form_submit"
                      className="mr-2"
                      type="button"
                      style={{ cursor: "pointer" }}
                    >
                      No
                    </a>
                    <a
                      onClick={() => updatePromptStatus()}
                      id="form_submit"
                      type="button"
                      style={{ cursor: "pointer" }}
                    >
                      Yes
                    </a>
                  </div>
                  <p style={{ color: "red" }}>
                    {/* {state.errors?.image_obj
                        ? state.errors?.image_obj
                        : ""} */}
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActiveArchiveModal;
