import React, { useContext, useState } from "react";
import AppContext from "../../context/AppContext";
import Axios from "../../axios/axiosInstance";

function AddEditProgTags({ modal, setModal,name, setName, getTagsList }) {
  const { setSpinner } = useContext(AppContext);
  const [error, setError] = useState();
  const token = JSON.parse(localStorage.getItem("AUTH_USER_TOKEN_KEY"));
  var noBlankOrEmptyPattern = /^(?!\s*$).+/;

  const validateField = () => {
    if (!name || !noBlankOrEmptyPattern.test(name)) {
      setError("This field is required");
    } else {
      addEditProgTags();
    }
  };

  const clearField = () => {
    setModal({
      show:false,
      type:""
    });
    setName("")
    setError("")
  }

  const addEditProgTags = () => {
    setSpinner(true);
    let obj = {
      name: name,
    };
    const meth = modal.type === "edit" ? Axios.put: Axios.post;
    const url = modal.type === "edit" ?  "/" + modal.id : ""
    meth(
      `${process.env.REACT_APP_BASE_URL}api/ProgramTag${url}`,
      obj,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then(function (response) {
        setSpinner(false);
        clearField();
        getTagsList();
      })
      .catch(function (error) {
        setSpinner(false);
        console.log(error);
      });
  };

  return (
    <div
      className={`modal fade bd-example-modal-sm ${modal.show ? "show" : ""}`}
      style={{ display: `${modal.show ? "block" : "none"}` }}
    >
      <div className="modal-dialog modal-md" style={{ maxWidth: "500px" }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {modal.type === "edit" ? "Update" : "Add New"} Tag
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => {
                clearField();
              }}
            >
              <span>&times;</span>
            </button>
          </div>
          <div className="row modal-body">
            <div className="col-md-12 form-group">
              <div className="mb-2">
                <label
                  htmlFor="example-search-input"
                  className="col-form-label"
                >
                  Tag Name
                </label>
                <input
                  value={name}
                  // onChange={(e) => onChangeUpdate(e, "name")}
                  onChange={(e) =>{ setName(e.target.value); setError("")}}
                  // onBlur={(e) => validateFields("name")}
                  className="form-control"
                  type="text"
                  id="name"
                />
              </div>
              <p style={{ color: "red" }}>
                {error ? error : ""}
              </p>
            </div>
          </div>

          <div className="modal-footer justify-content-center">
            <button
              type="button"
              data-dismiss="modal"
              className="btn btn-info"
              onClick={() => {
                validateField();
              }}
            >
              {modal.type === "edit" ? "Update" : "Submit"}
            </button>
          </div>
          {/* <p>{state.errMessages}</p> */}
        </div>
      </div>
    </div>
  );
}

export default AddEditProgTags;
