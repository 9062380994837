import React, { useContext, useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import AddNew from './AddNew';
import Axios from '../../axios/axiosInstance';
import AppContext from '../../context/AppContext';
import StoryFlowDelModal from './StoryFlowDelModal';

function StoryFlowCats() {
  const [categoryArray, setCategoryArray]  =useState([])
  const [modal, setModal] = useState(
   { show:false,
    type:"add"}
  )
  const [delModal, setDelModal] = useState({
    show:false,
    id:""
  })
  const [categoryName, setCategoryName] = useState()
  const { spinner,setSpinner } = useContext(AppContext);
  const token = JSON.parse(localStorage.getItem("AUTH_USER_TOKEN_KEY"))

//  const onChangeUpdate = (event, field) => {
//     setState({ [field]: event.target.value });
//   }

//   const validateFields = (field) => {
//     const errors = { ...state.errors };
//     let requiredFields = ["name"];
//     if (field != "") {
//       if (state[field].trim() != "") {
//         setState((prevState) => ({
//           errors: {
//             ...prevState.errors,
//             [field]: "",
//           },
//         }));
//       } else {
//         setState((prevState) => ({
//           errors: {
//             ...prevState.errors,
//             [field]: "This field is required",
//           },
//         }));
//       }
//     } else {
//       requiredFields.forEach((field1) => {
//         if (!state[field1]) {
//           errors[field1] = "This field is required";
//         }
//       });
//       setState({ errors: errors });
//     }
//     return errors;
//   }

  // const clearField = () => {
  //   document.getElementById("name").value = "";
  //   setState({
  //     isUpdate: false,
  //     addNew: false,
  //     errors: {},
  //     name: "",
  //   });
  // }

  // componentDidUpdate() {
  //   if (state.imageTemp && state.audioTemp) {
  //     setState({ preloader: false });
  //     addStoryFlow();
  //   }
  // }

  // componentWillMount() {
  //   window.scroll({
  //     top: 0,
  //     behavior: 'smooth'
  //   });
  //   getStoryFlowCategoryList();
  // }

  // const deleteCatgory = () => {
  //   let obj = {
  //     method: "deleteStoryFlowCat",
  //     storyflow_cat_id: state.deleteId,
  //   };
  //   let that = this;
  //   Axios
  //     .post(
  //       "https://ybw8xm9rj0.execute-api.us-east-2.amazonaws.com/v1/getstoryflows2",
  //       obj
  //     )
  //     .then(function (response) {
  //       console.log(response);

  //       if (response.data.message == "Success") {
  //         console.log(response);
  //         that.setState({ ShowDeleteModel: false, deleteId: 0 });
  //         // that.getStoryFlowCategoryList();

  //         // arrange indexing after element delete
  //         const catsList = [...that.state.categoryArray];

  //         // remove ele from list
  //         catsList.splice(that.state.deleteItemIndex, 1);

  //         // minus 1 from which index element remove
  //         const arrageArray = catsList.map((ele, index) => {
  //           if (index >= that.state.deleteItemIndex) {
  //             ele.order_idx -= 1;
  //           }
  //           return ele;
  //         });

  //         that.dragAndDropApiCall(arrageArray);
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }

  const getStoryFlowCategoryList = () => {
    setSpinner(true)
    let that = this;
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}api/StoryFlowCategory?page=1&limit=10`,
      {
        headers:{
          "Authorization": "Bearer " + token
        }
      }
    )
      .then(function (response) {
        console.log("response", response);
        setSpinner(false);
        setCategoryArray(response.data.data);
      })
      .catch(function (error) {
        setSpinner(false);
        console.log(error);
        that.setState({ preloader: false });
      });
  }

   // drag and drop function
   const handleDrag = (result) => {
    let tempUser = [...categoryArray];
    let [selectedRow] = tempUser.splice(result.source.index, 1);
    tempUser?.splice(result?.destination?.index, 0, selectedRow);
    let newArr = tempUser.map((items, index) => {
      return {
       ...items,
        order_idx: index,
      }
    });
    setCategoryArray(newArr);
    
    dragAndDropApiCall(newArr);
  };

  const dragAndDropApiCall = (oderItems) => {
    setSpinner(true);
    const obj = {
      data: oderItems,
    };
    Axios
      .put(`${process.env.REACT_APP_BASE_URL}api/StoryFlowCategory`, obj, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        setSpinner(false);
      })
      .catch(function (error) {
        setSpinner(false);
      });
  };

  useEffect(() => {
    getStoryFlowCategoryList();
  },[])

  console.log("sdfdsgd");
  return (
    <div className="body-bg">
        {/* {state.preloader && (
          <div id="preloader">
            <div className="loader"></div>
          </div>
        )} */}
        <div className="horizontal-main-wrapper">
          {/* <Header _this={this} history={props.history} /> */}
          <div
            className="main-content-inner"
            // onClick={() => setState({ showMenu: false })}
          >
            <div className="container">
              <div className="row">
                <div className="col-12 mt-5">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="listHead">
                          Story Flow Categories
                        </p>
                        <input
                          className="btn btn-info mb-3 ml-auto"
                          data-toggle="modal"
                          data-target=".bd-example-modal-sm"
                          type="submit"
                          value="Add New"
                          // onClick={() => setState({ addNew: true })}
                          onClick={() => setModal({
                            show:true,
                            type:"add"
                          })}
                        />
                      </div>
                      <div className="single-table">
                        <div className="table-responsive">
                        <DragDropContext
                          onDragEnd={(results) => handleDrag(results)}
                        >
                          <table className="table table-hover progress-table text-center">
                            <thead className="text-uppercase">
                              <tr>
                                <th scope="col">NO</th>
                                <th scope="col">CATEGORY NAME</th>
                                <th scope="col">POSITION</th>
                                <th scope="col">ACTION</th>
                              </tr>
                            </thead>
                            <Droppable droppableId={categoryArray[0]}>
                              {(provided) => (
                                <tbody
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  {categoryArray?.length ? (
                                    categoryArray?.map((data, index) => {
                                      return (
                                        <Draggable
                                          draggableId={"" + data.id}
                                          index={index}
                                          key={data.id}
                                          type="TASK"
                                        >
                                          {(provider) => (
                                            <tr
                                              key={data.id}
                                              ref={provider.innerRef}
                                              {...provider.draggableProps}
                                              {...provider.dragHandleProps}
                                            >
                                              {console.log("data", data)}

                                              <th scope="row">
                                                {data ? index + 1 : ""}
                                              </th>
                                              <td>{data.name}</td>
                                              <td>
                                                {data ? data.order_idx + 1 : ""}
                                              </td>
                                              <td>
                                                <ul className="d-flex justify-content-center">
                                                  <li>
                                                    <a
                                                      onClick={() => {
                                                        setModal({
                                                          show: true,
                                                          type: "edit",
                                                          id: data.id,
                                                        });
                                                        setCategoryName(data.name);
                                                      }}
                                                    >
                                                      <span
                                                        className="status-p "
                                                        data-toggle="modal"
                                                        data-target="#exampleModalCenter2"
                                                      >
                                                        <i className="fa fa-pencil"></i>
                                                      </span>
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      onClick={() =>
                                                        setDelModal({
                                                          show: true,
                                                          id: data.id,
                                                        })
                                                      }
                                                    >
                                                      <span
                                                        className="status-p "
                                                        data-toggle="modal"
                                                        data-target="#exampleModalCenter2"
                                                      >
                                                        <i className="fa fa-trash"></i>
                                                      </span>
                                                    </a>
                                                  </li>
                                                </ul>
                                              </td>
                                            </tr>
                                          )}
                                        </Draggable>
                                      );
                                    })
                                  ) : (!spinner &&
                                    <tr>
                                      <td colspan="4">{"No Result found!"}</td>
                                    </tr>
                                  )}
                                </tbody>
                              )}
                            </Droppable>
                          </table>
                        </DragDropContext>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        {modal.show &&  <AddNew
          modal={modal}
          setModal={setModal}
          categoryName={categoryName}
          setCategoryName={setCategoryName}
          getStoryFlowCategoryList={getStoryFlowCategoryList}
          />}

          {delModal.show && <StoryFlowDelModal
          delModal={delModal}
          setDelModal={setDelModal}
          getStoryFlowCategoryList={getStoryFlowCategoryList}
          />}
        </div>
        {/* <Footer /> */}
      </div>
  )
}

export default StoryFlowCats
