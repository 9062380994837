import { useContext } from "react";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Form, Modal } from "react-bootstrap";
import AppContext from "../../context/AppContext";
import Axios from "../../axios/axiosInstance";

const SubsModal = ({ modal, setModal, getSubscriptionList, page, value, onChange}) => {
  const token = JSON.parse(localStorage.getItem("AUTH_USER_TOKEN_KEY"));
  const { setSpinner } = useContext(AppContext);

  const addEditProgram = () => {
    setSpinner(true);
    let obj = {
        "event": {
            "expiration_at_ms": value.getTime(),
            "app_user_id": modal.id
        }
    };
    Axios.post(`${process.env.REACT_APP_BASE_URL}api/membershipByAdmin`, obj, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(function (response) {
        getSubscriptionList(page)
        // setSpinner(false);
        console.log("response", response);
        setModal({
          show: false,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <Modal centered show={modal.show} tabindex="-1" className="sub-modal" onHide={() => {setModal({ show: false }); onChange(new Date())}} >
        <Modal.Header>
          <Modal.Title>User Details</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>User Id</Form.Label>
            <Form.Control type="email" placeholder="name@example.com" value={modal.id}/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="w-100">Extend To</Form.Label>
            <DatePicker className={"form-control"} onChange={onChange} value={value} minDate={new Date()}/>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center pt-0 mb-3">
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-info"
            onClick={()=>addEditProgram()}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>

    </>
  )
}

export default SubsModal