import axios from "axios";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// const showInternetAlert = () => {
//   showMessage("error", "No internet connection. Please check your connection.");
// };

// const showGenericErrorAlert = () => {
//   showMessage("error", "An error occurred. Please try again later.");
// };

Axios.interceptors.request.use((config) => {
  // if (!window.navigator.onLine) {
  //   showInternetAlert();
  //   return Promise.reject(new axios.Cancel("No internet connection"));
  // }

  const token = localStorage.getItem("user.token");

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["x-token"] = token;
  }

  return config;
});

Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isCancel(error)) {
      // showInternetAlert();
      return Promise.reject(error);
    }

    if (error.response && error.response.status === 401) {
      // window.location.replace("/");
      window.location.replace("https://www.humanstreamsapp.com/#/");
    }

    return Promise.reject(error);
  }
);

export default Axios;
