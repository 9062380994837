import Axios from "../../axios/axiosInstance";
import React, { useContext } from "react";
import AppContext from "../../context/AppContext";

function DelPopularPrompt({delModal, setDelModal, getCategoryList}) {
  const { setSpinner } = useContext(AppContext)
  const token = JSON.parse(localStorage.getItem("AUTH_USER_TOKEN_KEY"))

 console.log("delModaldelModal",delModal);
  const addEditPromptHandle = async () => {
    console.log("sdsfsfd");
    const tagIds = (delModal?.data?.PromptTags || []).map((item) => item.id).join(",");
      const catIds = (delModal?.data?.PromptCategories || []).map((item) => item?.id).join(",");
      const programCatIds = (delModal?.data?.ProgramCategories || []).map((item) => item.id).join(",");
      const programTagIds = (delModal?.data?.ProgramTags || []).map((item) => item.id).join(",");
      const storyFlowCatIds = delModal?.data?.StoryFlowCategories?.length > 0 ? delModal?.data?.storyFlowCategory.map((item) => item.id).join(",") : "";
    try {
      const url = `${process.env.REACT_APP_BASE_URL}api/Prompt/${delModal.id}`;
        const body = {
            type: delModal.data.type,
            name: delModal.data.name,
            description: delModal.data.description,
            tags: tagIds,
            audio_duration: delModal.data.audio_duration,
            audio: delModal.data.audio,
            image:delModal.data.image,
            popular:false,
            image_thumb: delModal.data.image_thumb,
            program_cat_ids: programCatIds,
            program_tag_ids: programTagIds,
            ...(delModal.data.type === "Story Flow"
              ? { storyflow_cat_ids: delModal?.data?.StoryFlowCategories.length > 0 ? storyFlowCatIds : delModal?.data?.storyFlowCategory?.id?.toString()}
              : { prompt_cat_ids: catIds }),
          };

         
      await Axios.put(url, body, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      setDelModal({
        show: false,
        id: "",
        data:""
      })
      getCategoryList()

    } catch (error) {
      console.error("Error in addEditPromptHandle:", error);
    }
  };

  return (
    <div
      className={`modal fade bd-example-modal-sm ${
        delModal.show ? "show" : ""
      }`}
      style={{
        display: `${delModal.show? "block" : "none"}`,
      }}
      id="exampleModalCenter2"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-sm"
        role="document"
      >
        <div className="modal-content">
          <div className="login-area p-0">
            <div className="login-box d-block  text-center">
              <form className="m-0">
                <div className="login-form-head">
                  <img src="./assets/image/delete.svg" alt="" />
                </div>
                <div className="login-form-body">
                  <div className="">
                    <h4 className="pop_up_text">
                      Are you sure you want to delete this Popular Prompt ?
                    </h4>
                  </div>

                  <div className="submit-btn-area mt-4 d-flex">
                    <a
                      onClick={() =>
                        setDelModal({
                          show:false,
                          id:""
                        })
                      }
                      id="form_submit"
                      className="mr-2"
                      type="button"
                      style={{ cursor: "pointer" }}
                    >
                      Cancel
                    </a>
                    <a
                      onClick={() => addEditPromptHandle()}
                      id="form_submit"
                      type="button"
                      style={{ cursor: "pointer" }}
                    >
                      delete
                    </a>
                  </div>
                  <p style={{ color: "red" }}>
                    {/* {state.errors?.image_obj ? state.errors?.image_obj : ""} */}
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DelPopularPrompt;
