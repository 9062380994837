import Axios from "../../axios/axiosInstance";
import React, { useContext, useEffect, useState } from "react";
import ReactSelect from "react-select";

import AppContext from "../../context/AppContext";
import Resizer from "react-image-file-resizer";

function AddEditPrompt({
  modal,
  setModal,
  input,
  setInput,
  errors,
  setErrors,
  picture,
  setPicture,
  audio,
  setAudio,
  getPromptsList,
  activeTab,
  page,
  dailyCat,
  setDailyCat
}) {
  const [options, setOptions] = useState({
    categoriesList: [],
    recommendedCategoryList: [],
    recommendedTagList: [],
    tagList: [],
    storyFlowCategoriesList: [],
  });
  const [edit, setEdit] = useState(false);
  const [typesList, setTypeList] = useState([
    { label: "Prompt", value: "prompt" },
    { label: "Story Flow", value: "story_flow" },
  ]);
  const [uploadedMedia, setUploadedMedia] = useState({
    image: "",
    thumb: "",
    audiofile: "",
  });
  let typeSelected = { label: "Prompt", value: "prompt" };
  const [compressedImage, setCompressedImage] = useState(null);
  const token = JSON.parse(localStorage.getItem("AUTH_USER_TOKEN_KEY"));
  const { spinner, setSpinner } = useContext(AppContext);

  const handleChange = (e, name) => {
    setInput((prev) => ({ ...prev, [name]: e }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  console.log("input", input);

  const validationHandle = () => {
    const errorText = "This field is required";
    var noBlankOrEmptyPattern = /^(?!\s*$).+/;
    console.log("input.type.value===", input.type.value === "story_flow" && (!input.storyFlowCategory.value || input.storyFlowCategory.length < 1));


    if (!input.promptName || !noBlankOrEmptyPattern.test(input.promptName)) {

      setErrors((prev) => ({ ...prev, promptName: errorText }));
    }
    if (input.type.value === "prompt" && input?.category?.length < 1) {

      setErrors((prev) => ({ ...prev, category: errorText }));
    }
    if (input.type.label === "Story Flow" && !input.storyFlowCategory.value) {

      setErrors((prev) => ({ ...prev, storyflow: errorText }));
    }

    if (!input.description || !noBlankOrEmptyPattern.test(input.description)) {

      setErrors((prev) => ({ ...prev, description: errorText }));
    }
    if (!picture?.url) {

      setErrors((prev) => ({ ...prev, picture: errorText }));
    }
    if (!audio?.audio_duration) {
      setErrors((prev) => ({ ...prev, audio: errorText }));
    }

    else if (
      audio?.audio_duration &&
      picture?.url &&
      input.description &&
      (input.category.length > 0 || (input.storyFlowCategory.length > 0 || input.storyFlowCategory.value)) &&
      input.promptName &&
      noBlankOrEmptyPattern.test(input.promptName)
    ) {

      return true;
    }
  };


  const audioLength = (file) => {
    return new Promise((resolve) => {
      var objectURL = URL.createObjectURL(file);
      var mySound = new Audio([objectURL]);
      mySound.addEventListener(
        "canplaythrough",
        () => {
          URL.revokeObjectURL(objectURL);
          const hrs = ~~(mySound.duration / 3600);
          const mins = ~~((mySound.duration % 3600) / 60);
          const secs = ~~mySound.duration % 60;
          let ret = "";
          if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
          }
          ret += "" + mins + ":" + (secs < 10 ? "0" : "");
          ret += "" + secs;
          resolve(ret);
        },
        false
      );
    });
  };

  const handleChangePicture = async (event) => {
    const file = event.target.files[0];
    let type = event.target.files[0].type;
    if (type == "image/jpeg" || type == "image/jpg" || type == "image/png") {
      setPicture({
        file: file,
        url: URL.createObjectURL(file),
        name: file.name,
      });

      if (file) {
        try {
          Resizer.imageFileResizer(
            file,
            253, // Set the maximum width
            202, // Set the maximum height
            "JPEG", // Output format (options: JPEG, PNG)
            80, // Set the quality (0 to 100)
            0, // Rotation (0 for no rotation)
            (uri) => {
              const byteCharacters = atob(uri.split(",")[1]);
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              const blob = new Blob([byteArray], { type: "image/jpeg" });

              // Convert Blob to File
              const compressedFile = new File([blob], file.name, {
                type: "image/jpeg",
                lastModified: Date.now(),
              });

              setCompressedImage(compressedFile);
            },
            "base64" // Output type (options: base64, file, blob)
          );
        } catch (error) {
          console.error("Error compressing image:", error);
        }
      }

      setErrors((prev) => ({ ...prev, picture: "" }));
    } else {
      // setErrors((prev) => ({...prev, picture:"Image type is not supported!"}))
      return;
    }
  };



  const handleAudioChange = async (event) => {
    let type = event.target.files[0]?.type;
    let name = event.target.files[0]?.name;
    let nameType = name?.split(".")[1];


    if (
      type == "audio/mp3" ||
      type == "audio/wav" ||
      type == "audio/caf" ||
      type == "audio/mpeg" ||
      nameType == "caf"
    ) {
      if (nameType == "caf") {
        let file = event.target.files[0];

        const type = "audio/caf";
        // Instantiate copy of file, giving it new name.
        file = new File([file], file.name, { type: type });
        // setState({ [field]: file });
        setAudio({
          file: file,
        });
      } else {
        // setState({
        //   [field]: event.target.files[0],
        //   audio_duration: await audioLength(event.target.files[0]),
        // });
        setAudio({
          file: event.target.files[0],
          audio_duration: await audioLength(event.target.files[0]),
        });
        setErrors((prev) => ({ ...prev, audio: "" }));
      }
    } else {
      return;
    }
    // else {
    //   document.getElementById("audio").value = "";
    //   setState((prevState) => ({
    //     errors: {
    //       ...prevState.errors,
    //       [field]: "Only audio type is not supported!",
    //     },
    //   }));
    // }
  };

  const clearField = () => {
    document.getElementById("audio").value = "";
    document.getElementById("image").value = "";
    setInput({});
    setPicture({});
    setAudio({});
    setOptions({});
    setErrors({});
  };


  const mmssHandler = (string) => {
    let res;
    let splitter = string.split(":");

    if (splitter[0].length === 1) {
      res = "0" + splitter[0] + ":" + splitter[1];
      return res;
    } else {
      res = string;
      return res;
    }
  };
  console.log("inputinput11111111111", input);


  const addEditPromptHandle = async () => {
    try {
      const { image, audiofile, thumb } = uploadedMedia;
      console.log("inputinput22222222222", input);


      const areMediaUploaded = audio && thumb && image;
      const tagIds = (input?.tags || []).map((item) => item.value).join(",");
      const catIds = (input?.category || []).map((item) => item?.value).join(",");
      const programCatIds = (input?.recommendedCat || []).map((item) => item.value).join(",");
      const programTagIds = (input?.recommendedTag || []).map((item) => item.value).join(",");
      console.log("inputinput2221");

      const storyFlowCatIds = input?.storyFlowCategory?.length > 0 ? input?.storyFlowCategory.map((item) => item.value).join(",") : "";
      console.log("inputinput222");
      const body = {
        type: input.type?.value,
        name: input.promptName,
        description: input.description,
        tags: tagIds,
        audio_duration: mmssHandler(audio?.audio_duration),
        audio: audiofile,
        image,
        popular:dailyCat,
        image_thumb: thumb,
        program_cat_ids: programCatIds,
        program_tag_ids: programTagIds,
        ...(input.type.label === "Story Flow"
          ? { storyflow_cat_ids: input?.storyFlowCategory.length > 0 ? storyFlowCatIds : input?.storyFlowCategory?.value?.toString() }
          : { prompt_cat_ids: catIds }),
      };

      const method = modal.id ? Axios.put : Axios.post;
      const url = `${process.env.REACT_APP_BASE_URL}api/Prompt${modal.id ? "/" + modal.id : ""}`;

      await method(url, body, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      setModal({
        show: false,
        id: "",
      });
      setInput({});
      setPicture({});
      setAudio({});
      getPromptsList({ activeTab, page });
      setErrors({})
    } catch (error) {
      console.error("Error in addEditPromptHandle:", error);
    }
  };

  const handleSubmit = () => {
    if (modal.id && validationHandle() === true) {
      if (audio.file && picture.file) {


        setUploadedMedia({
          image: "",
          thumb: "",
          audiofile: "",
        });
        setEdit(true);
        uploadMedia();
      } else if (audio.file && !picture.file) {

        setUploadedMedia((prev) => ({ ...prev, audiofile: "" }));
        setEdit(true);
        uploadAudio();
        // addEditPromptHandle();
      } else if (!audio.file && picture.file) {

        setUploadedMedia((prev) => ({ ...prev, image: "", thumb: "" }));
        setEdit(true);
        uploadImg();
        uploadThumb();
        // addEditPromptHandle();
      } else {
        addEditPromptHandle();
      }
    } else {
      if (validationHandle() === true) {
        uploadMedia();
      }
    }
  };

  // Implement the logic for uploading media
  const uploadMedia = () => {
    uploadImg();
    uploadAudio();
    uploadThumb();
  };

  useEffect(() => {
    if (
      modal.type === "add" &&
      uploadedMedia.audiofile &&
      uploadedMedia.thumb &&
      uploadedMedia.image &&
      validationHandle()
    ) {
      addEditPromptHandle();
    } else {
      return;
    }
  }, [modal.type, uploadedMedia.audio, uploadedMedia.thumb, uploadedMedia.image]);

  useEffect(() => {
    if (
      edit === true &&
      uploadedMedia.audiofile &&
      uploadedMedia.thumb &&
      uploadedMedia.image &&
      validationHandle()
    ) {
      addEditPromptHandle();
    } else {
      return;
    }
  }, [edit, uploadedMedia]);


  // upload thumb image
  const uploadThumb = async () => {
    let obj = {
      extension: "." + compressedImage?.type.split("/")[1],
      type: compressedImage?.type,
    };
    Axios.post(`${process.env.REACT_APP_BASE_URL}api/getPreSignedUrl`, obj, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(function (response) {
        setUploadedMedia((prev) => ({
          ...prev,
          thumb: response.data.data.fileName,
        }));
        let body = compressedImage;
        Axios.put(response.data.data.url, body, {
          headers: {
            "Content-type": compressedImage?.type,
          },
        }).then(function (res) {

          console.log("res", res);
        });
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  // upload image api
  const uploadImg = async () => {
    let obj = {
      extension: "." + picture.file.type.split("/")[1],
      type: picture.file.type,
    };
    Axios.post(`${process.env.REACT_APP_BASE_URL}api/getPreSignedUrl`, obj, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(function (response) {
        setUploadedMedia((prev) => ({
          ...prev,
          image: response.data.data.fileName,
        }));
        let body = picture.file;
        Axios.put(response.data.data.url, body, {
          headers: {
            "Content-type": picture.file.type,
          },
        }).then(function (res) {
          console.log("res", res);
        });
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  // upload audio api
  const uploadAudio = async () => {
    let obj = {
      extension: "." + audio?.file?.type.split("/")[1],
      type: audio?.file?.type,
    };
    Axios.post(`${process.env.REACT_APP_BASE_URL}api/getPreSignedUrl`, obj, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(function (response) {
        setUploadedMedia((prev) => ({
          ...prev,
          audiofile: response.data.data.fileName,
        }));
        let body = audio.file;
        Axios.put(response.data.data.url, body, {
          headers: {
            "Content-type": audio.file.type,
          },
        }).then(function (res) {
          console.log("res", res);
        });
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };


  const getCategoryList = () => {
    let url =
      input.type.value == "prompt"
        ? `${process.env.REACT_APP_BASE_URL}api/PromptCategory?page=1&limit=10`
        : `${process.env.REACT_APP_BASE_URL}api/StoryFlowCategory?page=1&limit=10`;
    Axios.get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(function (response) {

        let optionArray = response.data.data.map((ele) => {
          return { label: ele.name, value: ele.id };
        });
        setOptions((prev) => ({ ...prev, categoriesList: optionArray }));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // recommended program list
  const getRecommendedCategoryList = () => {
    let obj = {
      method: "onlyListProgramCat",
    };
    let that = this;
    Axios.get(`${process.env.REACT_APP_BASE_URL}api/ProgramCategory?page=1&limit=10`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(function (response) {

        let optionArray = response.data.data.map((ele) => {
          return { label: ele.name, value: ele.id };
        });
        setOptions((prev) => ({
          ...prev,
          recommendedCategoryList: optionArray,
        }));
        // that.setState({
        //   recommendedCatArray: response.data.data.Items,
        //   recommendedCatList: optionArray,
        // });
        // that.getPromptsList();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // recommended list
  const getRecommendedTagList = () => {
    let obj = {
      method: "getProgramTags",
    };
    let that = this;
    Axios.get(`${process.env.REACT_APP_BASE_URL}api/ProgramTag?page=1&limit=10`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(function (response) {
        let optionArray = response.data.data.map((ele) => {
          return { label: ele.name, value: ele.id };
        });
        setOptions((prev) => ({ ...prev, recommendedTagList: optionArray }));

        // that.setState({
        //   recommendedTagArray: response.data.data.Items,
        //   recommendedTagList: optionArray,
        // });
        // that.getPromptsList();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getTagsList = () => {
    let obj = {
      method: "getPromptTags",
    };
    let that = this;
    Axios.get(`${process.env.REACT_APP_BASE_URL}api/PromptTag?page=1&limit=10`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(function (response) {
        let optionArray = response.data.data.map((ele) => {
          return { label: ele.name, value: ele.id };
        });

        setOptions((prev) => ({ ...prev, tagList: optionArray }));

        // that.getPromptsList();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getStoryFlowCategoriesList = () => {
    let obj = {
      method: "onlyListStoryFlowCat",
    };
    let that = this;
    Axios.get(`${process.env.REACT_APP_BASE_URL}api/StoryFlowCategory?page=1&limit=10`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(function (response) {
        let optionArray = response.data.data.map((ele) => {
          return { label: ele.name, value: ele.id };
        });

        setOptions((prev) => ({
          ...prev,
          storyFlowCategoriesList: optionArray,
        }));

        // if (response.data.message == "Success" && response.data.data.Items.length) {
        //   let optionArray = response.data.data.Items.map((ele) => {
        //     return { label: ele.name, value: ele.storyflow_cat_id };
        //   });
        //   setOptions((prev) => ({
        //     ...prev,
        //     storyFlowCategoriesList: optionArray,
        //   }));
        // }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getPromptDetailsHandle = (id) => {
    setSpinner(true);
    Axios.get(`${process.env.REACT_APP_BASE_URL}api/Prompt/${id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(function (response) {
        setSpinner(false);
        setInput({
          promptName: response.data.data.name,
          type: {
            label: response.data.data.type === "prompt" ? "Prompt" : "Story Flow",
            value: response.data.data.type === "prompt" ? "prompt" : "story_flow",
          },
          storyFlowCategory:
            response.data.data.StoryFlowCategories.length > 0
              ?
              // response.data.data.StoryFlowCategories.map((items) => {
              //     return {
              //       label: items.name,
              //       value: items.id,
              //     };
              //   })
              {
                label: response.data.data.StoryFlowCategories[0].name,
                value: response.data.data.StoryFlowCategories[0].id,
              }

              : {}
          ,
          category:
            response.data.data.PromptCategories.length > 0
              ? response.data.data.PromptCategories.map((items) => {
                return {
                  label: items.name,
                  value: items.id,
                };
              })
              : [],
          tags:
            response.data.data.PromptTags.length > 0
              ? response.data.data.PromptTags.map((items, index) => {
                return {
                  label: items.name,
                  value: items.id,
                };
              })
              : [],
          recommendedCat:
            response.data.data.ProgramCategories.length > 0
              ? response.data.data.ProgramCategories.map((items) => {
                return {
                  label: items.name,
                  value: items.id,
                };
              })
              : [],
          recommendedTag:
            response.data.data.ProgramTags.length > 0
              ? response.data.data.ProgramTags.map((items, index) => {
                return {
                  label: items.name,
                  value: items.id,
                };
              })
              : [],
          description: response.data.data.description,
        });
        setUploadedMedia({
          image: response.data.data.image,
          audiofile: response.data.data.audio,
          thumb: response.data.data.image_thumb,
        });
        setPicture({
          url: response.data.data.image,
          file: "",
        });
        setAudio({
          file: "",
          audio_duration: response.data.data.audio_duration,
        });
        setDailyCat(response.data.data.popular)
        // setTagsArray(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  useEffect(() => {
    if (modal.type === "add")
      setInput((prev) => ({
        ...prev,
        type: {
          label: "Prompt",
          value: "prompt",
        },
      }));
  }, [modal.type]);

  useEffect(() => {
    getRecommendedCategoryList();
    getRecommendedTagList();
    getTagsList();
    getStoryFlowCategoriesList();
  }, []);

  useEffect(() => {
    if (input.type) {
      getCategoryList();
    }
  }, [input.type]);

  useEffect(() => {
    if (modal.id) {
      getPromptDetailsHandle(modal.id);
    }
  }, [modal.id]);

  return (
    <div
      className={`modal fade bd-example-modal-sm ${modal.show ? "show" : ""}`}
      style={{ display: `${modal.show ? "block" : "none"}` }}
    >
      <div className="modal-dialog modal-md" style={{ maxWidth: "700px" }}>
        <div
          className="modal-content"
          style={{
            height: "900px",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title">{modal.type === "edit" ? "Update" : "Add New"} Prompt</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => {
                setModal({
                  show: false,
                });
                clearField();
              }}
            >
              <span>&times;</span>
            </button>
          </div>
          <div className="row modal-body">
            <div className="col-md-8 form-group">
              <div className="mb-2">
                <label htmlFor="example-search-input" className="col-form-label">
                  Prompt Name
                </label>
                <input
                  // value={state.prompt_name}
                  // onChange={(e) => onChangeUpdate(e, "prompt_name")}
                  // onBlur={(e) => validateFields("prompt_name")}
                  value={input.promptName}
                  onChange={(e) => {
                    setInput((prev) => ({
                      ...prev,
                      promptName: e.target.value,
                    }));
                    setErrors((prev) => ({ ...prev, promptName: "" }));
                  }}
                  className="form-control"
                  type="search"
                  id="prompt_name"
                  maxLength="30"
                />
              </div>
              <p style={{ color: "red" }}>
                {/* {state.errors?.prompt_name ? state.errors?.prompt_name : ""} */}
                {errors.promptName ? errors.promptName : ""}
              </p>

              <div className="mb-2">
                <label htmlFor="example-search-input" className="col-form-label">
                  Type
                </label>
                <ReactSelect
                  options={typesList}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  onChange={(e) => {
                    handleChange(e, "type");
                    setInput((prev) => ({ ...prev, category: [], storyFlowCategory: [] }));
                    setErrors((prev) => ({ ...prev, category: "", storyflow: "" }))
                  }}
                  // value={state?.typeSelected}
                  value={input.type}
                />
              </div>

              {input?.type?.value === "prompt" ? (
                <>
                  <div className="mb-2">
                    <label htmlFor="example-search-input" className="col-form-label">
                      Category
                    </label>

                    {/* <select className="form-control" value={state.prompt_cat_id}
                        onChange={(e) => onChangeUpdate(e, "prompt_cat_id")} onBlur={(e) => validateFields("prompt_cat_id")}>
                        <option value="" disabled>Please Select</option>
                        {state.categoryArray.map((category, index) => {
                          return (<option value={category.prompt_cat_id}>{category.name}</option>);
                        })}
                      </select> */}

                    <ReactSelect
                      options={options.categoriesList}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      onChange={(e) => {
                        handleChange(e, "category");
                        setErrors((prev) => ({ ...prev, category: "" }));
                      }}
                      // onBlur={(e) => validateFields("prompt_cat_ids")}
                      // allowSelectAll={true}
                      // isSearchable={true}
                      // value={state.optionSelected}
                      value={input.category}
                    />
                  </div>
                  <p style={{ color: "red" }}>
                    {/* {state.errors?.prompt_cat_ids
                          ? state.errors?.prompt_cat_ids
                          : ""} */}
                    {errors.category ? errors.category : ""}
                  </p>
                </>
              ) : (
                <>
                  <div className="mb-2">
                    <label htmlFor="example-search-input" className="col-form-label">
                      Story Flow Category
                    </label>
                    <ReactSelect
                      options={options.storyFlowCategoriesList}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={(e) => {
                        handleChange(e, "storyFlowCategory");
                        setErrors((prev) => ({ ...prev, storyflow: "" }));
                      }}
                      // onBlur={(e) => validateFields("storyflow_cat_ids")}
                      // allowSelectAll={true}
                      // isSearchable={true}
                      // value={state.storyFlowCategoriesSelected}
                      value={input.storyFlowCategory}
                    />
                  </div>
                  <p style={{ color: "red" }}>
                    {errors.storyflow ? errors.storyflow : ""}
                    {/* {state.errors?.storyflow_cat_ids
                          ? state.errors?.storyflow_cat_ids
                          : ""} */}
                  </p>
                </>
              )}

              {/* <div className="col-md-12 form-group"> */}
                <div className="mb-2">
                  <input type="checkbox"
                    // value={dailyCat}
                    name="description"
                    onChange={(e) => setDailyCat(e.target.checked)}
                    checked={dailyCat}
                    className="daily_CustomCheckbox"
                    id="name" />

                  <label for="wp-comment-cookies-consent"> Popular Prompt
                  </label>
                </div>
              {/* </div>/ */}

              <div className="mb-2">
                <label htmlFor="example-search-input" className="col-form-label">
                  Tags
                </label>
                <ReactSelect
                  options={options.tagList}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={(e) => handleChange(e, "tags")}
                  // onChange={(e) => setInput((prev) => ({...prev, tags:e}))}
                  allowSelectAll={true}
                  isSearchable={true}
                  // value={state.tagsSelected}
                  value={input.tags}
                />
              </div>
              <p style={{ color: "red" }}>{/* {state.errors?.tags ? state.errors?.tags : ""} */}</p>
              {/* recommended category field */}
              <div className="mb-2">
                <label htmlFor="example-search-input" className="col-form-label">
                  Recommendation Category
                </label>
                <ReactSelect
                  options={options.recommendedCategoryList}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={(e) => handleChange(e, "recommendedCat")}
                  allowSelectAll={true}
                  isSearchable={true}
                  // value={state.recommendedCatSelected}
                  value={input.recommendedCat}
                />
              </div>
              {/* recommended tag field */}
              <div className="mb-2">
                <label htmlFor="example-search-input" className="col-form-label">
                  Recommendation Tag
                </label>
                <ReactSelect
                  options={options.recommendedTagList}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={(e) => handleChange(e, "recommendedTag")}
                  allowSelectAll={true}
                  isSearchable={true}
                  value={input.recommendedTag}
                />
              </div>

              <div className="mb-2">
                <label htmlFor="example-search-input" className="col-form-label">
                  Description
                </label>
                <textarea
                  rows="5"
                  // value={description}
                  // onChange={(e) => onChangeUpdate(e, "description")}
                  // onBlur={(e) => validateFields("description")}
                  value={input.description}
                  onChange={(e) => {
                    setErrors((prev) => ({ ...prev, description: "" }));
                    setInput((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }));
                  }}
                  className="form-control"
                  type="search"
                  id="description"
                />
              </div>
              <p style={{ color: "red" }}>
                {/* {state.errors?.description ? state.errors?.description : ""} */}
                {errors.description ? errors.description : ""}
              </p>

              <div className="mb-2">
                <label htmlFor="example-search-input" className="col-form-label">
                  Choose Background Image
                </label>
                <div className="input-group col-xs-12">
                  <input
                    // onChange={(e) => handleFileInputChange(e, "image_obj")}
                    // onBlur={(e) => validateFields("image_obj")}
                    onChange={(e) => {
                      handleChangePicture(e);
                    }}
                    // value={picture?.file}
                    accept="image/*"
                    type="file"
                    className="upload_input upload-field-2 form-control"
                    readOnly={true}
                    placeholder="Upload Image"
                    id="image"
                  />
                  <span className="input-group-btn">
                    <label className="upload_btn upload-field btn btn-info" htmlFor="image">
                      Browse
                    </label>
                  </span>
                </div>
                <p style={{ color: "red" }}>
                  {/* {state.errors?.image_obj ? state.errors?.image_obj : ""} */}
                  {errors.picture ? errors.picture : ""}
                </p>
              </div>
              <div className="">
                <label htmlFor="example-search-input" className="col-form-label">
                  Choose Audio
                </label>
                <div className="input-group col-xs-12">
                  <input
                    // onChange={(e) => handleFileInputChange(e, "audio_obj")}
                    // onBlur={(e) => validateFields("audio_obj")}
                    onChange={(e) => handleAudioChange(e)}
                    // value={audio}
                    accept="audio/mp3"
                    type="file"
                    className="upload_input upload-field form-control"
                    readOnly={true}
                    placeholder="Upload Audio"
                    id="audio"
                  />
                  <span className="input-group-btn">
                    <label className="upload_btn upload-field btn btn-info" htmlFor="audio">
                      Browse
                    </label>
                  </span>
                </div>

                <p style={{ color: "red" }}>
                  {/* {state.errors?.audio_obj ? state.errors?.audio_obj : ""} */}
                  {errors.audio ? errors.audio : ""}
                </p>
              </div>
            </div>
            <div
              className="col-md-4 form-group"
              style={{ Padding: "1rem", borderLeft: "2px solid" }}
            >
              <div>
                <p>Prompt Preview</p>
              </div>

              {picture?.url && (
                <div>
                  <div
                    style={{
                      backgroundImage: `url("${picture.file ? picture.url : process.env.REACT_APP_MEDIA_URL + picture.url
                        }")`,
                      backgroundSize: "cover",
                      borderRadius: "10%",
                      width: "150px",
                      height: "150px",
                      marginTop: "60px",
                      marginLeft: "27px",
                    }}
                  >
                    <div
                      style={{
                        height: "80px",
                        width: "100%",
                        backgroundColor: "rgb(255, 255, 255)",
                        position: "relative",
                        opacity: "0.8",
                        bottom: "-50%",
                        wordBreak: "break-all",
                      }}
                    >
                      <b>
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "12px",
                            margin: "0 20px",
                            lineHeight: "15px",
                            overflow: "hidden",
                            height: "47px",
                          }}
                        >
                          {input?.promptName}
                        </p>
                      </b>
                    </div>
                  </div>
                </div>
              )}

              <div
                style={{
                  display: "none",
                  opacity: "0",
                }}
              >
                {/* {state.previewImgUrl && (
                      <img
                        id="prompt-img"
                        style={{
                          borderRadius: "50%",
                          width: "150px",
                          height: "150px",
                          borderRadius: "50%",
                          marginTop: "60px",
                          marginLeft: "27px",
                        }}
                        src={state.previewImgUrl}
                      />
                    )} */}
              </div>
            </div>
          </div>

          <div className="modal-footer justify-content-center">
            <button
              type="button"
              data-dismiss="modal"
              className="btn btn-info"
              // onClick={() => {
              //   if (state.isUpdate) {
              //     updateStory();
              //   } else {
              //     uploadMedias("both");
              //   }
              // }}
              onClick={() => handleSubmit()}
            >
              {modal.type === "edit" ? "Update" : "Upload"}
            </button>
          </div>
          {/* <p>{state.errMessages}</p> */}
        </div>
      </div>
    </div>
  );
}

export default AddEditPrompt;
