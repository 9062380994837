import React, { useContext, useState } from 'react'
import AppContext from '../../context/AppContext';
import Axios from '../../axios/axiosInstance';

function AddEditTag({modal, setModal, tagName, setTagName, getTagsList}) {
  const { setSpinner } = useContext(AppContext)
  const [error, setError] = useState();
  const token = JSON.parse(localStorage.getItem("AUTH_USER_TOKEN_KEY"));
  var noBlankOrEmptyPattern = /^(?!\s*$).+/;

  const validateField = () => {
    if (!tagName || !noBlankOrEmptyPattern.test(tagName)) {
      setError("This field is required");
    } else {
      addEditTag();
    }
  };

  const clearFields = () => {
    setModal({});
    setTagName("");
    setError("");
  };


  const addEditTag = () => {
    setSpinner(true);
    let obj = {
      name: tagName,
    };
    const meth = modal.type === "edit" ?Axios.put: Axios.post;
    const url = modal.type === "edit" ?  "/" + modal.id : ""
    meth(
      `${process.env.REACT_APP_BASE_URL}api/PromptTag${url}`,
      obj,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then(function (response) {
        setSpinner(false);
        clearFields();
        getTagsList();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div
    className={`modal fade bd-example-modal-sm ${modal.show ? "show" : ""
      }`}
    style={{ display: `${modal.show ? "block" : "none"}` }}
  >
    <div
      className="modal-dialog modal-md"
      style={{ maxWidth: "500px" }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{modal.type === "edit" ? "Update" : "Add New"} Tag</h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => {
              setModal({show:false})
            }}
          >
            <span>&times;</span>
          </button>
        </div>
        <div className="row modal-body">
          <div className="col-md-12 form-group">
            <div className="mb-2">
              <label
                htmlFor="example-search-input"
                className="col-form-label"
              >
                Tag Name
              </label>
              <input
                // value={state.name}
                // onChange={(e) => onChangeUpdate(e, "name")}
                // onBlur={(e) => validateFields("name")}
                value={tagName}
                onChange={(e) => {
                  setError("");
                  setTagName(e.target.value);
                }}
                className="form-control"
                type="text"
                id="name"
              />
            </div>
            <p style={{ color: "red" }}>
              {/* {state.errors?.name
                ? state.errors?.name
                : ""} */}
                {error ? error : ""}
            </p>


          </div>

        </div>

        <div className="modal-footer justify-content-center">
          <button
            type="button"
            data-dismiss="modal"
            className="btn btn-info"
            onClick={() => {
              validateField();
            }}
          >
            {modal.type === "edit" ? "Update" : "Submit"}
          </button>
        </div>
        {/* <p>{state.errMessages}</p> */}
      </div>
    </div>
  </div>
  )
}

export default AddEditTag
