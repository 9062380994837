import Axios from "../../axios/axiosInstance";
import React, { useContext, useEffect, useState } from "react";

import archive from "../../assets/image/archive.svg";
import unarchive from "../../assets/image/un-archive.svg";
import deactivate from "../../assets/image/deactivate.png";
import restore from "../../assets/image/restore.png";
import AddPrompt from "./AddEditPrompt";
import AppContext from "../../context/AppContext";
import ActiveArchiveModal from "./ActiveArchiveModal";
import Pagination from "../../widgets/Pagination";

function Prompt() {
  const [promptList, setPromptList] = useState("");
  let tabList = ["Active", "Archived", "Deactivated"];
  const [activeTab, setActiveTab] = useState("Active");
  const [modal, setModal] = useState({
    show: false,
    type: "add",
  });
  const [delArchiveModal, setDelArchiveModal] = useState({
    show: false,
    type: "",
  });
  const [input, setInput] = useState({
    category:[],
    type: {
      label:  "Prompt" ,
      value:  "prompt"
    },
    storyFlowCategory:{}
  });
  const [dailyCat, setDailyCat] = useState(false);
  const [errors, setErrors] = useState({});
  const [picture, setPicture] = useState();
  const [audio, setAudio] = useState();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const { spinner, setSpinner } = useContext(AppContext);
  const token = JSON.parse(localStorage.getItem("AUTH_USER_TOKEN_KEY"));

 
  const updatePromptStatus = () => {
    setSpinner(true);
    let url = delArchiveModal.type === "archive" ? "archive" : "deactivate";
    let body =
      delArchiveModal.type === "archive"
        ? {
            archive: delArchiveModal.archive === true ? false : true,
          }
        : {
            deactivate: delArchiveModal.deactivate === true ? false : true,
          };
    Axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}api/Prompt/${delArchiveModal.id}/${url}`,
        body,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function (response) {
        setSpinner(false);
        getPromptsList({ activeTab });
        setDelArchiveModal({
          show: false,
          type: "",
        });
      })
      .catch(function (error) {
        setSpinner(false);
        console.log(error);
      });
  };

  const getPromptsList = ({ activeTab, search, page }) => {
    setSpinner(true);
    let filter =
      activeTab === "Active"
        ? "&deactivate=0&archive=0"
        : activeTab === "Deactivated"
        ? "&deactivate=1"
        : "&archive=1";
    setPromptList([]);
    Axios
      .get(
        `${process.env.REACT_APP_BASE_URL}api/Prompt?page=${
          page ? page : 1
        }&limit=10${search ? "&search=" + search : ""}${filter}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function (response) {
        console.log("responde", response);
        setSpinner(false);
        setPromptList(response.data);
      })
      .catch(function (error) {
        setSpinner(false);
        console.log(error);
        // that?.setState({ preloader: false });
        setSpinner(false);
      });
  };

 
  console.log("input", input);

  useEffect(() => {
    getPromptsList({ activeTab, search,page });
  }, [activeTab, search, page]);

  console.log("activeTAbbb", activeTab);

  return (
    <div className="body-bg">
      <div className="horizontal-main-wrapper">
        <div
          className="main-content-inner"
          // onClick={() => setState({ showMenu: false })}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 mt-5">
                <div className="card">
                  <div className="card-body">
                    <div className="promt-head">
                      <div className="tabPrompt">
                        {tabList?.map((ele, index) => (
                          <div
                            className={`tab-content ${
                              activeTab == ele ? "tab-active" : ""
                            }`}
                            onClick={
                              () => {setActiveTab(ele); setPage(1)}
                              // onChangeTab()
                            }
                          >
                            {ele}
                          </div>
                        ))}
                      </div>

                      <div className="d-flex justify-content-center flex-wrap align-items-center">
                        <span className="pr-2 position-relative mobSearch">
                          <input
                            className="form-control searchEle"
                            type="search"
                            placeholder="search by name..."
                            value={search}
                            onChange={(e) => {setSearch(e.target.value); setPage(1)}}
                            // onKeyUp={(e) => onSearch(e)}
                          />
                          <i className="fa fa-search searchIcon"></i>
                        </span>
                        <span>
                          <input
                            className="btn btn-info  float-right"
                            data-toggle="modal"
                            data-target=".bd-example-modal-sm"
                            type="submit"
                            value="Add New"
                            onClick={() =>
                              setModal({
                                show: true,
                                type: "add",
                              })
                            }
                            // onClick={() =>
                            //   setState({
                            //     addNew: true,
                            //     tags: "",
                            //     tagsSelected: null,
                            //   })
                            // }
                          />
                        </span>
                      </div>
                    </div>
                    <div className="single-table">
                      <div className="table-responsive">
                        <table className="table table-hover progress-table text-center">
                          <thead className="text-uppercase">
                            <tr>
                              <th scope="col" className="w-10">
                                No
                              </th>
                              <th scope="col" className="w-20">
                                PROMPT NAME
                              </th>
                              {/* <th scope="col">CATEGORY NAME</th> */}
                              {/* <th scope="col">POSITION</th> */}
                              <th scope="col" className="w-50">
                                DESCRIPTION
                              </th>
                              <th scope="col" className="w-20">
                                {" "}
                                ACTION
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {promptList?.data?.length
                              ? promptList?.data?.map((data, index) => {
                                  return (
                                    <tr key={index}>
                                      <th scope="row">{promptList.from + index}</th>
                                      <td>{data.name}</td>
                                      {/* <td>{data.prompt_cat ? data.prompt_cat.name : ''}</td> */}
                                      {/* <td>{data ? data.order_idx + 1 : ''}</td> */}
                                      <td>{data.description}</td>
                                      <td>
                                        <ul className="d-flex justify-content-center">
                                          <li>
                                            {console.log("data", data)}
                                            <a
                                              href={
                                                process.env
                                                  .REACT_APP_MEDIA_URL + 
                                                data.image
                                              }
                                              target="_blank"
                                            >
                                              <span className="status-p ">
                                                <i className="fa fa-eye"></i>
                                              </span>
                                            </a>
                                            {console.log(
                                              "stringprogramTagIds",
                                              data
                                            )}
                                          </li>
                                          <li>
                                            <a
                                              href={
                                                process.env
                                                  .REACT_APP_MEDIA_URL +
                                                data.audio
                                              }
                                              target="_blank"
                                            >
                                              <span className="status-p ">
                                                <i className="fa fa-play"></i>
                                              </span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              // onClick={() =>
                                              //   getPromptDetails(data.prompt_id)
                                              // }
                                              onClick={() => {
                                                setModal({
                                                  show: true,
                                                  type: "edit",
                                                  id: data.id,
                                                });
                                                // setInput({
                                                //   promptName: data.name,
                                                //   type: {
                                                //     label: data.type,
                                                //     value: data.type,
                                                //   },
                                                //   category:data.PromptCategories.map((items) => {
                                                //     return {
                                                //       "label": ,
                                                //       "value": "8f382e9a-ea9d-498a-90a1-25a984b1cbe8"
                                                //     }
                                                //   })
                                                // });
                                              }}
                                            >
                                              <span
                                                className="status-p "
                                                data-toggle="modal"
                                                data-target="#exampleModalCenter2"
                                              >
                                                <i className="fa fa-pencil"></i>
                                              </span>
                                            </a>
                                          </li>
                                          {(activeTab === "Active" ||
                                            activeTab === "Archived") && (
                                            <li>
                                              <a
                                                onClick={() => {
                                                  setDelArchiveModal({
                                                    show: true,
                                                    type: "archive",
                                                    archive: data.archive,
                                                    id: data.id,
                                                  });
                                                }}
                                              >
                                                <span
                                                  className={`status-p ${
                                                    data.archive ? "unArch" : ""
                                                  }`}
                                                  data-toggle="modal"
                                                  data-target="#exampleModalCenter2"
                                                >
                                                  {/* <i className="fa fa-archive cusArch"></i> */}
                                                  <img
                                                    className="cusArch"
                                                    src={
                                                      data.archive
                                                        ? unarchive
                                                        : archive
                                                    }
                                                    title={
                                                      data.archive
                                                        ? "unarchive"
                                                        : "archive"
                                                    }
                                                  />
                                                </span>
                                              </a>
                                            </li>
                                          )}

                                          {(activeTab === "Active" ||
                                            activeTab === "Deactivated") && (
                                            <li>
                                              <a
                                                onClick={() => {
                                                  setDelArchiveModal({
                                                    show: true,
                                                    type: "delete",
                                                    deactivate: data.deactivate,
                                                    id: data.id,
                                                  });
                                                }}
                                              >
                                                <span
                                                  className={`status-p`}
                                                  data-toggle="modal"
                                                  data-target="#exampleModalCenter2"
                                                >
                                                  {/* <i className="fa fa-archive cusArch"></i> */}
                                                  <img
                                                    className="cusArch"
                                                    src={
                                                      data.deactivate
                                                        ? restore
                                                        : deactivate
                                                    }
                                                    title={
                                                      data.deactivate
                                                        ? "restore"
                                                        : "deactivate"
                                                    }
                                                  />
                                                </span>
                                              </a>
                                            </li>
                                          )}
                                        </ul>
                                      </td>
                                    </tr>
                                  );
                                })
                              : !spinner && (
                                  <tr>
                                    <td colSpan="6">{"No Result found!"}</td>
                                  </tr>
                                )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {modal.show && (
          <AddPrompt
            modal={modal}
            setModal={setModal}
            input={input}
            setInput={setInput}
            errors={errors}
            setErrors={setErrors}
            audio={audio}
            setAudio={setAudio}
            picture={picture}
            setPicture={setPicture}
            getPromptsList={getPromptsList}
            activeTab={activeTab}
            page={page}
            dailyCat={dailyCat}
            setDailyCat={setDailyCat}
          />
        )}
        {delArchiveModal.show && (
          <ActiveArchiveModal
            modal={delArchiveModal}
            setModal={setDelArchiveModal}
            updatePromptStatus={updatePromptStatus}
          />
        )}
        {console.log("promptList", promptList)}
        <Pagination
          totalPages={promptList?.last_page}
          currentPage={promptList?.current_page}
          page={page}
          setPage={setPage}
        />
      </div>

      {/* <Footer /> */}
    </div>
  );
}

export default Prompt;
