import React, { useContext, useState } from 'react';
import Axios from '../../axios/axiosInstance';
import AppContext from '../../context/AppContext';
import { useLocation } from 'react-router-dom';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

const Promocode = () => {
    // State for the selected country code and phone number
    const [selectedCode, setSelectedCode] = useState(); // Default country code
    const [phoneNumber, setPhoneNumber] = useState('');

    // Context for managing spinner state
    const { spinner, setSpinner } = useContext(AppContext);

    // Retrieve the authorization token from local storage
    const token = JSON.parse(localStorage.getItem("AUTH_USER_TOKEN_KEY"));

    // Hook for accessing the current location (URL)
    const location = useLocation();

    // Handle click event on the 'Create' button
    const handleCreate = () => {
        setSpinner(true); // Show spinner while processing
        Axios.post(`${process.env.REACT_APP_BASE_URL}api/PromoCode/redeemClick`, {
            phoneCode: '+'+selectedCode, // Include the selected country code
            phoneNumber: phoneNumber, // Include the phone number
            code: new URLSearchParams(location.search).get('code') // Get the code from URL parameters
        }, {
            headers: {
                Authorization: `Bearer ${token}`, // Include Authorization header
            },
        })
            .then(() => {
                setSpinner(false); // Hide spinner on success
                const code = new URLSearchParams(location.search).get('code');
                // Redirect to the Apple Store redeem URL with the code parameter
                // window.location.href = `https://apps.apple.com/redeem/?ctx=offercodes&id=1540277909&code=${code}`;
                window.location.href = `https://itunes.apple.com/app/id1540277909`;
            })
            .catch(() => {
                setSpinner(false); // Hide spinner on error
            });
    };

    // Handle phone number change to allow only numeric characters
    const handlePhoneNumberChange = (isValid, value, selectedCountryData, fullNumber, extension) => {
        // Replace any non-numeric characters
        const numericValue = value.replace(/\D/g, '');
        setPhoneNumber(numericValue);
    };

    return (
        <div className="promocode">
            <div className="promocode__body d-flex align-items-center justify-content-center flex-column">
                <div className="promocode__content pb-5 w-100">
                    <div className="text-center">
                        <img src="./assets/image/logoP.png" alt="logop" className='logop mb-3' />
                        <div className="heading">humanStreams</div>
                    </div>
                    <p className='mt-5 text-center mb-3'>
                        Hi! Please enter your phone <br /> number to redeem the offer.
                    </p>

                    <div>
                        <IntlTelInput
                            containerClassName='intl-tel-input inputbox w-100 inputbox'
                            inputClassName='form-control border-0'
                            autoPlaceholder={false}
                            defaultCountry='us'
                            onSelectFlag={(currentNumber, selectedCountryData) => {
                                setSelectedCode(selectedCountryData?.dialCode);
                            }}
                            useMobileFullscreenDropdown={true}
                            preferredCountries={[]}
                            onPhoneNumberChange={handlePhoneNumberChange}
                            value={phoneNumber}
                            placeholder='Phone'
                            telInputProps={{
                                maxLength: '10', // Set a reasonable max length
                                type: 'tel', // Ensure the input type is telephone
                                inputMode: 'numeric', // Improve numeric input experience on mobile
                            }}
                        />
                    </div>
                </div>
                <div className="promocode__footer w-100 mt-5">
                    <button
                        className='btn btn--blue w-100'
                        onClick={handleCreate}
                        disabled={spinner} // Disable button while loading
                    >
                        {spinner ? 'Loading...' : 'Redeem Offer'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Promocode;
