import Axios from '../../axios/axiosInstance';
import React, { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/AppContext';
import moment from 'moment';
import FeedbackModal from './FeedbackModal';

function Feedback() {
  const [feedbackArray, setFeedbackArray] = useState([])
  const [limit, setLimit] = useState(10)
  const {spinner,setSpinner} = useContext(AppContext);
  const [modal, setModal] = useState({
    show:false,
    data:""
  })
  const token = JSON.parse(localStorage.getItem("AUTH_USER_TOKEN_KEY"));
  const [ratingList, setRatingList] = useState([
    1,2,3,4,5
  ]);

  const getStarClass = (rating, ele) => {
    if (ele <= rating) {
      return 'fa fa-star';
    }
    else if (ele > rating && ele - rating < 1) {
      return 'fa fa-star-half-o';
    }
    else {
      return 'fa fa-star-o';
    }
  }

  // const onChangeUpdate = (event, field) => {
  //   setState({ [field]: event.target.value });
  // }

  // const validateFields = (field) => {
  //   const errors = { ...state.errors };
  //   let requiredFields = ["name"];
  //   if (field != "") {
  //     if (state[field].trim() != "") {
  //       setState((prevState) => ({
  //         errors: {
  //           ...prevState.errors,
  //           [field]: "",
  //         },
  //       }));
  //     } else {
  //       setState((prevState) => ({
  //         errors: {
  //           ...prevState.errors,
  //           [field]: "This field is required",
  //         },
  //       }));
  //     }
  //   } else {
  //     requiredFields.forEach((field1) => {
  //       if (!state[field1]) {
  //         errors[field1] = "This field is required";
  //       }
  //     });
  //     setState({ errors: errors });
  //   }
  //   return errors;
  // }

  // componentWillMount() {
  //   window.scroll({
  //     top: 0,
  //     behavior: 'smooth'
  //   });
  //   getFeedbackList();
  // }

  // getStarClass(rating, ele) {
  //   if (ele <= rating) {
  //     return 'fa fa-star';
  //   }
  //   else if (ele > rating && ele - rating < 1) {
  //     return 'fa fa-star-half-o';
  //   }
  //   else {
  //     return 'fa fa-star-o';
  //   }
  // }

  const getFeedbackList = ({limit}) => {
    setSpinner(true)
    let obj = {
      method: "getCustomerFeedBack",
    };

    let that = this;
    // that.setState({ preloader: true });
    Axios
      .get(
        `${process.env.REACT_APP_BASE_URL}api/FeedBack?page=1&limit=${limit ? limit : 10}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function (response) {
        setSpinner(false);
        setFeedbackArray(response.data)
      })
      .catch(function (error) {
        console.log(error);
        // that.setState({ preloader: false });
      });
  }

  // const showMorefeedHandle = (currentArray) => {

  //   if (!currentArray) {
  //     alert('No more data')
  //     return
  //   }
  //   let that = this;
  //   that.setState({ preloader: true });
  //   Axios
  //     .post(
  //       "https://ybw8xm9rj0.execute-api.us-east-2.amazonaws.com/v1/getstoryflows2",
  //       {
  //         method: "getCustomerFeedBack",
  //         LastEvaluatedKey: currentArray.LastEvaluatedKey
  //       }
  //     )
  //     .then(function (response) {
  //       // console.log(response);

  //       if (response.data.message == "Success") {
  //         that.setState({
  //           preloader: false,
  //           feedbackArray: [...currentArray.feedbackArray, ...response.data.data.Items],
  //           LastEvaluatedKey: response.data.data.LastEvaluatedKey
  //         });
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       that.setState({ preloader: false });
  //     });
  // }

  useEffect(() => {
    getFeedbackList({limit})
  },[limit])

  console.log("feedback array",feedbackArray);

  return (
    <div className="body-bg">
    {/* {state.preloader && (
      <div id="preloader">
        <div className="loader"></div>
      </div>
    )} */}
    <div className="horizontal-main-wrapper">
      {/* <Header _this={this} history={props.history} /> */}
      <div
        className="main-content-inner"
        // onClick={() => setState({ showMenu: false })}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 mt-5">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="listHead pb-3">
                      Feedback
                    </p>

                  </div>
                  <div className="single-table">
                    <div className="table-responsive">

                      <table className="table table-hover progress-table text-center" >
                        <thead className="text-uppercase">
                          <tr>
                            <th scope="col" className="w-10">S.NO.</th>
                            <th scope="col" className="w-15">USER NAME</th>
                            <th scope="col" className="w-15">EMAIL</th>
                            <th scope="col" className="w-10">Date</th>
                            <th scope="col" className="w-25">MESSAGE</th>
                            <th scope="col" className="w-15">Rating</th>
                            <th scope="col" className="w-10">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {feedbackArray?.data?.length > 0 ?
                           feedbackArray?.data?.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <th scope="row">{data ? index + 1 : ''}</th>
                                  <td>{data.User?.firstName+" "+data.User?.lastName}</td>
                                  <td>{data.User.email}</td>
                                  <td>{moment(data.updated_at).format('MM/DD/YYYY')}</td>
                                  <td>{data.message.length > 30 ? data.message.slice(0, 29) + '...' : data.message}</td>
                                  <td>
                                    <span className="starRate" title={data?.rating}>
                                      {ratingList.map(el => <i className={getStarClass(data?.rating, el)}></i>)}
                                    </span>
                                  </td>
                                  <td>
                                    <ul className="d-flex justify-content-center">
                                      <li>
                                        <a
                                          // onClick={() =>
                                          //   setState({
                                          //     showModal: true,
                                          //     viewFeedBack: data
                                          //   })
                                          // }
                                          onClick={() => setModal({
                                            show:true,
                                            data:data
                                          })}
                                        >
                                          <span
                                            className="status-p "
                                            data-toggle="modal"
                                            data-target="#exampleModalCenter2"
                                          >
                                            <i className="fa fa-eye"></i>
                                          </span>
                                        </a>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              )
                            }
                            )
                            : <tr>
                              {/* <td colspan="7">{!spinner && 'No Result found!'}</td> */}
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {feedbackArray?.total < 10  ? "": <button
        disabled={feedbackArray?.data?.length == feedbackArray?.total}
        style={{
          background: `${feedbackArray?.data?.length == feedbackArray?.total  ? '#b70000' : 'linear-gradient(to right, #83c8d2 0%, #768990 100%)'}`,
          color: '#fff',
          fontWeight: '600',
          borderRadius: '5px',
          border: 'none',
          margin: 'auto',
          display: 'block',
          padding: '5px 20px'
        }} 
        onClick={() => setLimit(limit+10)}>
        {feedbackArray?.data?.length == feedbackArray?.total ? 'No More Record!' : 'Show More'}
      </button>}

      <FeedbackModal
        modal={modal}
        setModal={setModal}
        ratingList={ratingList}
      />

    </div>
  </div >
  )
}

export default Feedback
