import React, { useContext, useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import Axios from '../../axios/axiosInstance';
import AppContext from '../../context/AppContext';
import Pagination from "../../widgets/Pagination";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';


const Promocodes = () => {
    // State to manage the visibility of the modal
    const [show, setShow] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    // Context to access global spinner state and setter function
    const { spinner, setSpinner } = useContext(AppContext);

    // State to store the list of promo codes and users
    const [promoCodeList, setPromoCodes] = useState([]);
    const [userList, setUserList] = useState([]);

    // State to manage pagination for promo codes and users
    const [page, setPage] = useState(1);
    const [userPage, setUserPage] = useState(1);

    // State to check if there are more users to load and if the dropdown is open
    const [hasMoreUsers, setHasMoreUsers] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    // State to manage the selected user and code name input
    const [selectedUser, setSelectedUser] = useState(null);
    const [codeName, setCodeName] = useState('');

    const [dateValue, onChange] = useState(new Date());
    const [description, setDescription] = useState(null);


    // References to the dropdown select element and the search input
    const selectRef = useRef(null);
    const inputRef = useRef(null);

    // Retrieve the token from local storage for authentication
    const token = JSON.parse(localStorage.getItem("AUTH_USER_TOKEN_KEY"));

    // Close the modal and reset relevant states
    const handleClose = () => {
        setShow(false);
        setUserList([]);
        setUserPage(1);
        setHasMoreUsers(true);
        setIsDropdownOpen(false);
        setSelectedUser(null);
        setCodeName('');
        setSearchQuery('');
    };

    // Show the modal and fetch the user list
    const handleShow = () => {
        setShow(true);
        getAllUserList(1);
    };

    // Handle search input change
    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        // Reset user list and page when search query changes
        setUserList([]);
        setUserPage(1);
        setHasMoreUsers(true);

        // Fetch the user list with the new search query
        getAllUserList(1, query);
    };

    // Fetch the list of promo codes with pagination
    const getPromoCodes = (page) => {
        setSpinner(true);
        Axios.get(`${process.env.REACT_APP_BASE_URL}api/PromoCode?page=${page}&limit=10`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                setPromoCodes(response?.data);
                setSpinner(false);
            })
            .catch(() => {
                setSpinner(false);
            });
    };

    // Fetch the list of users with pagination
    const getAllUserList = (page, query = '') => {
        setSpinner(true);
        Axios.get(`${process.env.REACT_APP_BASE_URL}api/user?page=${page}&limit=10&search=${query}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                if (response?.data?.data?.length) {
                    setUserList(prev => [...prev, ...response.data.data]);
                    setHasMoreUsers(response.data.next_page_url !== null);
                } else {
                    setHasMoreUsers(false);
                }
                setSpinner(false);
            })
            .catch(() => {
                setSpinner(false);
            });
    };

    // Handle the scroll event to load more users when the dropdown is scrolled to the bottom
    const handleUserScroll = (e) => {
        const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
        if (bottom && hasMoreUsers && !spinner) {
            setUserPage(prev => prev + 1);
            getAllUserList(userPage + 1);
        }
    };

    // Toggle the dropdown visibility
    const toggleDropdown = () => {
        setIsDropdownOpen(prev => !prev);
    };

    // Handle the selection of a user from the dropdown
    const handleOptionClick = (user) => {
        setSelectedUser(user);
        // Close the dropdown only when an item is selected
        setIsDropdownOpen(false);
    };

    // Update the code name state on input change
    const handleCodeNameChange = (e) => {
        setCodeName(e.target.value);
    };

    // Handle the creation of a new promo code
    const handleCreate = () => {
        if (!codeName || !selectedUser || !description) {
            alert("Please enter the Code Name and select a user and description.");
            return;
        }

        setSpinner(true);
        Axios.post(`${process.env.REACT_APP_BASE_URL}api/PromoCode`, {
            code: codeName,
            User: selectedUser.id,  // Send the selected user ID,
            expiry: moment.utc(dateValue).format('YYYY-MM-DD HH:mm:ss'),
            description: description
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(() => {
                // Close the modal, refresh the promo codes list, and stop the spinner
                handleClose();
                getPromoCodes(page);
                setSpinner(false);
            })
            .catch(() => {
                setSpinner(false);
            });
    };

    // Copy the promo code link to the clipboard
    const copyToClipboard = async (linkCode) => {
        try {
            // const promoLink = `http://103.149.154.53/HumanStreamsAdmin/#/promocode?code=${linkCode}`; // For local server
            const promoLink = `https://www.humanstreamsapp.com/#/promocode?code=${linkCode}`; // For live server
            await navigator.clipboard.writeText(promoLink);
            alert('Copied!')
        } catch (error) {
            console.error('Failed to copy the value:', error);
        }
    };


    // Fetch the promo codes initially and when the page changes
    useEffect(() => {
        getPromoCodes(page);
    }, [page]);

    // Handle clicks outside of the dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target) && inputRef.current && !inputRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <>
            <div className="body-bg">
                <div className="horizontal-main-wrapper">
                    <div className="main-content-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 mt-5">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between align-items-center mb-2">
                                                <div className="listHead">Promo Codes</div>
                                                <button onClick={handleShow} className='btn btn-info shadow-none'>
                                                    Create Code
                                                </button>
                                            </div>
                                            <div className="single-table">
                                                <div className="table-responsive">
                                                    <table className="table table-hover progress-table text-center">
                                                        <thead className='text-uppercase'>
                                                            <tr>
                                                                <th>NO</th>
                                                                <th>THERAPIST NAME</th>
                                                                <th>CODE</th>
                                                                <th>EXPIRY DATE</th>
                                                                <th>PLAN DESCRIPTION</th>

                                                                <th>USERS WHO CLICKED</th>
                                                                <th>USERS WHO REDEEMED</th>
                                                                <th>ACTION</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {promoCodeList?.data?.length ? (
                                                                promoCodeList?.data?.map((data, index) => (
                                                                    <tr key={index}>
                                                                        <th>{index + 1}</th>
                                                                        <td>
                                                                            {data?.User?.firstName ? `${data?.User?.firstName || ''} ${data?.User?.lastName || ''}` : `${data?.User?.phoneCode + data?.User?.phoneNumber || ''}`}
                                                                        </td>
                                                                        <td>{data?.code}</td>
                                                                        <td>{data?.expiry ? moment(data?.expiry).format('MM-DD-YYYY') : '-'}</td>
                                                                        <td>{data?.description ? data?.description : '-'}</td>
                                                                        <td>{data?.clickCount}</td>
                                                                        <td>{data?.redeemCount}</td>
                                                                        <td>
                                                                            <img src="./assets/image/copyicon.svg" onClick={() => copyToClipboard(data?.code)} alt="icon" className='tbl_action_icon' />
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : !spinner && (
                                                                <tr>
                                                                    <td colSpan="6">No Results Found!</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Pagination
                            totalPages={promoCodeList?.last_page}
                            currentPage={promoCodeList?.current_page}
                            page={page}
                            setPage={setPage}
                        />
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} className='modalpr'>
                <Modal.Header closeButton>
                    <h5 className='modal-title'>Add New Code</h5>
                    <img src="./assets/image/modalclose.svg" alt="icon" className='modal-close' onClick={handleClose} />
                </Modal.Header>
                <Modal.Body className='row'>
                    <div className="col-md-12 form-group">
                        <label className='col-form-label'>Code Name</label>
                        <input
                            type="text"
                            className='form-control'
                            value={codeName}
                            onChange={handleCodeNameChange}  // Update code name on input change
                        />
                    </div>
                    <div className="col-md-12 form-group">
                        <label className='col-form-label'>Assign To</label>
                        <div className="custom-select-box" ref={selectRef}>
                            <div className="select-box-selected" onClick={toggleDropdown}>
                                {selectedUser ? `${selectedUser.phoneCode} ${selectedUser.phoneNumber}` : "Select User"}
                            </div>
                            {isDropdownOpen && (
                                <div
                                    ref={selectRef}
                                    onScroll={handleUserScroll}
                                    className="select-box-options"
                                    style={{ maxHeight: '200px', overflowY: 'auto' }}
                                >
                                    <input
                                        type="text"
                                        className='form-control mb-2'
                                        placeholder="Search Users"
                                        value={searchQuery}
                                        onChange={handleSearchChange}  // Update search query on input change
                                        ref={inputRef}
                                    />
                                    {userList.length ? (
                                        userList.map((user, index) => (
                                            <div
                                                key={index}
                                                className="select-box-option"
                                                onClick={() => handleOptionClick(user)}
                                            >
                                                {`${user.phoneCode} ${user.phoneNumber}`}
                                            </div>
                                        ))
                                    ) : (
                                        <div>No Users Found</div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-12 form-group  cusdate">
                        <label className='col-form-label'>Expiry Date</label>
                        <DatePicker className='form-control b-0' onChange={onChange} value={dateValue} minDate={new Date()} />
                    </div>
                    <div className="col-md-12 form-group">
                        <label className='col-form-label'>Plan Description</label>
                        <input
                            type="text"
                            className='form-control'
                            placeholder='e.g. $5 per month'
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>

                </Modal.Body>

                <Modal.Footer className='justify-content-center'>
                    <button className='btn btn-info shadow-none' onClick={handleCreate}>Create</button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Promocodes;
