import Axios from "../../axios/axiosInstance";
import React, { useContext } from "react";
import AppContext from "../../context/AppContext";

function DelCatModal({delModal, setDelModal, getCategoryList}) {
  const { setSpinner } = useContext(AppContext)
  const token = JSON.parse(localStorage.getItem("AUTH_USER_TOKEN_KEY"))

    const deleteCatgory = () => {
    setSpinner(true)
    Axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}api/PromptCategory/${delModal.id}`,
        {
          headers: 
          {
            "Authorization": "Bearer " + token
          }
        }
      )
      .then(function (response) {
        console.log(response);
        setDelModal({
          show:false,
          id:""
        })
        setSpinner(false);
        getCategoryList();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div
      className={`modal fade bd-example-modal-sm ${
        delModal.show ? "show" : ""
      }`}
      style={{
        display: `${delModal.show? "block" : "none"}`,
      }}
      id="exampleModalCenter2"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-sm"
        role="document"
      >
        <div className="modal-content">
          <div className="login-area p-0">
            <div className="login-box d-block  text-center">
              <form className="m-0">
                <div className="login-form-head">
                  <img src="./assets/image/delete.svg" alt="" />
                </div>
                <div className="login-form-body">
                  <div className="">
                    <h4 className="pop_up_text">
                      Are you sure you want to delete this catgeory ?
                    </h4>
                  </div>

                  <div className="submit-btn-area mt-4 d-flex">
                    <a
                      onClick={() =>
                        setDelModal({
                          show:false,
                          id:""
                        })
                      }
                      id="form_submit"
                      className="mr-2"
                      type="button"
                      style={{ cursor: "pointer" }}
                    >
                      Cancel
                    </a>
                    <a
                      onClick={() => deleteCatgory()}
                      id="form_submit"
                      type="button"
                      style={{ cursor: "pointer" }}
                    >
                      delete
                    </a>
                  </div>
                  <p style={{ color: "red" }}>
                    {/* {state.errors?.image_obj ? state.errors?.image_obj : ""} */}
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DelCatModal;
