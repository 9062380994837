import React, { createContext, useState } from "react";

const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [spinner, setSpinner] = useState(false);
  const [toaster, setToaster] = useState({
    isShow: false,
    type: "success",
    message: "",
  });


  return (
    <AppContext.Provider
      value={{
        spinner,
        setSpinner,
        toaster,
        setToaster
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default AppContext;
