import React from "react";


const VideoModal = ({show, setShow, data}) => {


  return (
    <>
      <div className="overlay-video"> </div>
      <div className="vedio_container" >

        <div className="modal_body mt-5">
            <button className="modalCloseBtn" onClick={() => setShow({data:"", show:false})}>×</button>
          <video src={data} autoPlay controls controlsList="nodownload" >
            <source src={data} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {/* <iframe
            className="embed-responsive-item"
            width="854"
            height="480"
            src={src}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          /> */}
        </div>
        <div className="modal_footer mt-5"></div>
      </div>
    </>
  );
};

export default VideoModal;
