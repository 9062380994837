import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AddCategory from "./AddCategory";
import Axios from "../../axios/axiosInstance";
import AppContext from "../../context/AppContext";
import DelCatModal from "./DelCatModal";

function Category() {
  const [categoryArray, setCategoryArray] = useState([]);
  const [description, setDescription] = useState("");
  const [dailyCat, setDailyCat] = useState(false);
  const [modal, setModal] = useState({
    show: false,
    type: "add",
  });
  const [delModal, setDelModal] = useState({
    show: false,
    id: "",
  });

  const [category, setCategory] = useState();
  const { spinner, setSpinner } = useContext(AppContext);
  const token = JSON.parse(localStorage.getItem("AUTH_USER_TOKEN_KEY"));

  //  const onChangeUpdate = (event, field) => {
  //     setState({ [field]: event.target.value });
  //   }

  //   const validateFields = (field) => {
  //     const errors = { ...state.errors };
  //     let requiredFields = ["name"];
  //     if (field != "") {
  //       if (state[field].trim() != "") {
  //         setState((prevState) => ({
  //           errors: {
  //             ...prevState.errors,
  //             [field]: "",
  //           },
  //         }));
  //       } else {
  //         setState((prevState) => ({
  //           errors: {
  //             ...prevState.errors,
  //             [field]: "This field is required",
  //           },
  //         }));
  //       }
  //     } else {
  //       requiredFields.forEach((field1) => {
  //         if (!state[field1]) {
  //           errors[field1] = "This field is required";
  //         }
  //       });
  //       setState({ errors: errors });
  //     }
  //     return errors;
  //   }

  //   const clearField = () => {
  //     document.getElementById("name").value = "";
  //     setState({
  //       isUpdate: false,
  //       addNew: false,
  //       errors: {},
  //       name: "",
  //     });
  //   }

  //   const componentDidUpdate = () => {
  //     if (state.imageTemp && state.audioTemp) {
  //       setState({ preloader: false });
  //       addStoryFlow();
  //     }
  //   }

  //   const componentWillMount = () => {
  //     window.scroll({
  //       top: 0,
  //       behavior: 'smooth'
  //     });
  //     getCategoryList();
  //   }

  const getCategoryList = () => {
    setSpinner(true);
    Axios
      .get(
        `${process.env.REACT_APP_BASE_URL}api/PromptCategory?page=1&limit=10`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function (response) {
        setSpinner(false);
        setCategoryArray(response.data.data);
      })
      .catch(function (error) {
        setSpinner(false);
        console.log(error);
      });
  };

  console.log("category", categoryArray);

  // drag and drop function
  const handleDrag = (result) => {
    let tempUser = [...categoryArray];
    let [selectedRow] = tempUser.splice(result.source.index, 1);
    tempUser?.splice(result?.destination.index, 0, selectedRow);
    let newArr = tempUser.map((items, index) => {
      return {
       ...items,
        order_idx: index,
      }
    });
    setCategoryArray(newArr);
    dragAndDropApiCall(newArr);
  };

  const dragAndDropApiCall = (oderItems) => {
    setSpinner(true);
    const obj = {
      data: oderItems,
    };
    Axios
      .put(`${process.env.REACT_APP_BASE_URL}api/PromptCategory`, obj, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        setSpinner(false);
      })
      .catch(function (error) {
        setSpinner(false);
      });
  };

  useEffect(() => {
    getCategoryList();
  }, []);
  return (
    <div className="body-bg">
      <div className="horizontal-main-wrapper">
        {/* <Header _this={this} history={props.history} /> */}
        <div
          className="main-content-inner"
          // onClick={() => setState({ showMenu: false })}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 mt-5">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="listHead">Prompt Categories</p>
                      <input
                        className="btn btn-info mb-3 ml-auto"
                        data-toggle="modal"
                        data-target=".bd-example-modal-sm"
                        type="submit"
                        value="Add New"
                        onClick={() =>
                          setModal({
                            show: true,
                            type: "add",
                          })
                        }
                      />
                    </div>
                    <div className="single-table">
                      <div className="table-responsive">
                        <DragDropContext
                          onDragEnd={(results) => handleDrag(results)}
                        >
                          <table className="table table-hover progress-table text-center">
                            <thead className="text-uppercase">
                              <tr>
                                <th scope="col" className="">NO</th>
                                <th scope="col">CATEGORY NAME</th>
                                <th scope="col">Description</th>
                                <th scope="col">POSITION</th>
                                <th scope="col">ACTION</th>
                              </tr>
                            </thead>
                            <Droppable droppableId={categoryArray[0]}>
                              {(provided) => (
                                <tbody
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  {categoryArray?.length ? (
                                    categoryArray?.map((data, index) => {
                                      return (
                                        <Draggable
                                          draggableId={"" + data.id}
                                          index={index}
                                          key={data.id}
                                          type="TASK"
                                        >
                                          {(provider) => (
                                            <tr
                                              key={data.id}
                                              ref={provider.innerRef}
                                              {...provider.draggableProps}
                                              {...provider.dragHandleProps}
                                            >

                                              <th scope="row">
                                                {data ? index + 1 : ""}
                                              </th>
                                              <td>{data.name}</td>
                                              <td className="desc_td">
                                                {/* {data ? data.order_idx + 1 : ""} */}
                                                {data.description}
                                              </td>
                                              <td>
                                                {data ? data.order_idx + 1 : ""}
                                              </td>
                                             
                                              <td>
                                                <ul className="d-flex justify-content-center">
                                                  <li>
                                                    <a
                                                      onClick={() => {
                                                        console.log("dataaaa",data.daily);
                                                        setModal({
                                                          show: true,
                                                          type: "edit",
                                                          id: data.id,
                                                        });
                                                        setCategory(data.name);
                                                        setDescription(data.description);
                                                        setDailyCat(data.daily);
                                                      }}
                                                    >
                                                      <span
                                                        className="status-p "
                                                        data-toggle="modal"
                                                        data-target="#exampleModalCenter2"
                                                      >
                                                        <i className="fa fa-pencil"></i>
                                                      </span>
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      onClick={() =>
                                                        setDelModal({
                                                          show: true,
                                                          id: data.id,
                                                        })
                                                      }
                                                    >
                                                      <span
                                                        className="status-p "
                                                        data-toggle="modal"
                                                        data-target="#exampleModalCenter2"
                                                      >
                                                        <i className="fa fa-trash"></i>
                                                      </span>
                                                    </a>
                                                  </li>
                                                </ul>
                                              </td>
                                            </tr>
                                          )}
                                        </Draggable>
                                      );
                                    })
                                  ) : ( !spinner &&
                                    <tr>
                                      <td colspan="4">{"No Result found!"}</td>
                                    </tr>
                                  )}
                                </tbody>
                              )}
                            </Droppable>
                          </table>
                        </DragDropContext>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {modal.show && (
          <AddCategory
            modal={modal}
            setModal={setModal}
            category={category}
            setCategory={setCategory}
            getCategoryList={getCategoryList}
            description={description} 
            setDescription={setDescription}
            dailyCat={dailyCat}
            setDailyCat={setDailyCat}
          />
        )}
        {delModal.show && (
          <DelCatModal
            delModal={delModal}
            setDelModal={setDelModal}
            getCategoryList={getCategoryList}
          />
        )}
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Category;
